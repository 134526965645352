import React, { Fragment, useState, useEffect } from 'react';
import './modals.scss';
import { Trans } from '@lingui/react';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import imageIcon from '../../../images/viewer-icons/sound.svg';
import smallImageIcon from '../../../images/viewer-icons/small-sound-icon.svg';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import {
  deletePanoramaAudioSettings,
  getPanoramaAudioSettings,
  updatePanoramaAudioFile,
  uploadPanoramaAudioFile,
} from '../../../api-helper/api-panoramas';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { parseBoolean } from '../../utils/general-utils/general-utils';
import LoadingSpinnerNoBg from '../../utils/loading-spinner/loading-spinner-no-bg';
import { INewError } from '@vva/front/api-client/interfaces';

interface IProps {
  panorama: any;
  changeLeftSidebar: (state: string) => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
}

interface IFile {
  name: string;
  file: string;
  data?: any;
}

export interface IAudioSettings {
  audioLoop: boolean;
  playAcrossTour: boolean;
}

const AddAudio: React.FC<IProps> = ({ panorama, changeLeftSidebar, toggleNotification }) => {
  const [loading, setLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const [file, setFile] = useState<IFile>();
  const [soundsConfig, setSoundsConfig] = useState({
    audioLoop: false,
    playAcrossTour: false,
    audioName: '',
  });
  const { handleLogoutState } = useAuth();

  useEffect(() => {
    if (panorama?.id) {
      getAudioAsync();
    }
  }, [panorama]);

  const getAudioAsync = async () => {
    try {
      const audios = await getPanoramaAudioSettings(panorama.id);
      const audioLoop = audios.find(audio => audio.panoramaKey === 'AUDIO_LOOP');
      const playAcrossTour = audios.find(audio => audio.panoramaKey === 'AUDIO_PLAY_ACROSS');
      const audioName = audios.find(audio => audio.panoramaKey === 'AUDIO_NAME');
      if (audios.length) {
        const audioFile = {
          name: String(audioName?.panoramaValue || ''),
          file: audios[0].audioUrl,
        };
        setFile(audioFile);
        setImagePreview(true);
        setSoundsConfig({
          audioLoop: audioLoop ? parseBoolean(audioLoop.panoramaValue) : false,
          playAcrossTour: playAcrossTour ? parseBoolean(playAcrossTour.panoramaValue) : false,
          audioName: String(audioName?.panoramaValue || ''),
        });
      } else {
        setImagePreview(false);
      }
      setLoading(false);
    } catch (error) {
      setImagePreview(false);
      setLoading(false);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const uploadAudioFile = async (file: IFile) => {
    try {
      const upload = await uploadPanoramaAudioFile(file, panorama.id);
      if (upload) {
        setFile(file);
        setImagePreview(true);
      }
    } catch (error) {
      const err = error as INewError;
      handleError(error, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, err.getMessage());
    }
  };

  const updatePanoramaAudioSettings = async () => {
    try {
      await updatePanoramaAudioFile(panorama.id, soundsConfig);
      toggleNotification(EnumNotificationType.Success, 'Audio updated successfully');
    } catch (error) {
      toggleNotification(EnumNotificationType.Error, 'There was an error uploading the file');
    }
  };

  const deleteAudioFile = async () => {
    try {
      await deletePanoramaAudioSettings(panorama.id);
      toggleNotification(EnumNotificationType.Success, 'Audio deleted successfully');
      await getAudioAsync();
    } catch (error) {
      toggleNotification(EnumNotificationType.Error, 'There was an error deleting the audio');
    }
  };

  const onFileDrop = (e: any) => {
    const file = e.target.files;
    for (let i = 0; i <= file.length; i++) {
      let fileReader = new FileReader();
      const newFile = file[i];
      if (newFile) {
        fileReader.onload = () => {
          const file: IFile = {
            data: fileReader.result,
            file: newFile,
            name: newFile.name,
          };
          uploadAudioFile(file);
        };
        fileReader.readAsDataURL(newFile);
        fileReader.onabort = () => {
          alert('Reading aborted');
        };
        fileReader.onerror = () => {
          alert('Reading error!');
        };
      }
    }
  };

  return (
    <Fragment>
      <div className='modal-background-layer'></div>
      <div className='editor-modal-container-add' style={{ height: '500px', width: '762px' }}>
        <div className='editor-modal-title'>
          <h1 className='editor-modal-header-title-add'>
            <Trans id='Upload an audio file' />
          </h1>
        </div>
        <button className='close-button btn-remove-default' onClick={() => changeLeftSidebar('')}>
          <CloseButton />
        </button>
        {loading ? (
          <LoadingSpinnerNoBg />
        ) : (
          <div className='content-modal-audio'>
            <div className='add-image-container-modal'>
              {imagePreview ? (
                <div className='title-add-modal'>
                  <div className='upload-title-modal-image'>
                    <Trans id='Click "Add audio" to add the soundtrack to your tour.' />
                  </div>
                  <div className='upload-title-modal-image-second'>
                    <Trans id='If you wish to upload a different file, click "Delete audio"' />
                  </div>
                </div>
              ) : (
                <div className='title-add-modal'>
                  <div className='upload-title-modal-image'>
                    <Trans id='Upload your audio file below' />
                  </div>
                  <div className='upload-title-modal-image-second'>
                    <Trans id='The image format must be MP3' />
                  </div>
                </div>
              )}
              <div className='edit-modal-inside-container'>
                {imagePreview ? (
                  <div className='viewer-drop-zone' style={{ height: '230px', width: '70%', padding: '35px 50px' }}>
                    <div>
                      <img style={{ verticalAlign: 'middle', marginRight: '20px' }} src={smallImageIcon} alt='sound' /> {file?.name}
                    </div>
                    <div className='settings-config-option' style={{ width: '100%', margin: '27px 0' }}>
                      <div>
                        <Trans id='Repeat' render={({ translation }) => <div className='field-subtext-audio'>{translation}</div>} />
                      </div>
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='autoStartTour'
                            type='checkbox'
                            checked={soundsConfig.audioLoop}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setSoundsConfig({
                                ...soundsConfig,
                                audioLoop: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                            <span className={`slider-text-${soundsConfig.audioLoop ? 'on' : 'off'}`}>
                              {soundsConfig.audioLoop ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='settings-config-option' style={{ width: '100%' }}>
                      <div>
                        <Trans
                          id='Play across the tour'
                          render={({ translation }) => <div className='field-subtext-audio'>{translation}</div>}
                        />
                      </div>
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='autoStartTour'
                            type='checkbox'
                            checked={soundsConfig.playAcrossTour}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setSoundsConfig({
                                ...soundsConfig,
                                playAcrossTour: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                            <span className={`slider-text-${soundsConfig.playAcrossTour ? 'on' : 'off'}`}>
                              {soundsConfig.playAcrossTour ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='viewer-drop-zone' style={{ height: '230px', width: '70%', margin: '0 54px' }}>
                    {!imagePreview && <img className='icon-sounds-image' src={imageIcon} alt='icon' />}
                    <div className='helper-text-image-upload'>
                      <Trans id='Drop your audio file here' />
                      <div className='media-drop-or-add'>
                        <Trans id='or' />
                      </div>
                    </div>
                    <button className='select-an-image-file'>
                      <Trans id='Upload audio file' />
                    </button>
                    <input
                      style={{ height: imagePreview ? '150px' : '' }}
                      id='upload-multiple'
                      className='center-input'
                      type='file'
                      name='file-drop-zone'
                      multiple
                      onDragOver={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onDrop={(e: any) => onFileDrop(e)}
                      onChange={(e: any) => onFileDrop(e)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='modal-btn-container-audio'>
              <button onClick={deleteAudioFile} className='button-hover general-btn-delete general-btn-delete-active'>
                <Trans id='Delete audio' />
              </button>
              <button
                onClick={updatePanoramaAudioSettings}
                className={`upload-images-editor-modal-btn ${imagePreview ? 'editor-modal-btn-active-add' : ''}`}
              >
                <Trans id='Save changes' />
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default AddAudio;
