import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { updateUserAccountById, createAccount, getUserById } from '../../../api-helper/api-user';
// import { DropDownCountries, FilterableDropdownList } from '@vva/front';
import { usePasswordValidation } from '../../utils/custom-hooks/usePasswordValidation';
import backArrow from '../../../images/icons/modal-icons/back-arrow.svg';
import cross from '../../../images/icons/pwd-check-cross.svg';
import tick from '../../../images/icons/pwd-check-tick.svg';
import showPasswordIcon from '../../../images/icons/password-hidden.svg';
import hidePasswordIcon from '../../../images/icons/password-shown.svg';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import selectArrow from '../../../images/dashboard-icons/select-arrow.svg';
import './create-edit-account.scss';
import ModalAccountManagement from '../../utils/modal/modal-account-management';
import DatePicker from '../../utils/date-picker/date-picker';
import { EnumAccountStatus } from './account-management-canvas';
import CustomSelect from '../../utils/custom-select/custom-select';
import { defineMessage } from '@lingui/macro';
import { searchPartnerByKeyword } from '../../../api-helper/api-admin';
import arrow from '../../../images/dashboard-icons/select-arrow.svg';
import arrowDisabled from '../../../images/dashboard-icons/dropdown-arrow-disabled.svg';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { EnumProPlan, EnumProUserStatus, typeProPlan, typeProPlanNotMaster } from '../../../api-helper/interface/interfaces';
import { useHistory } from 'react-router';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import { Link } from 'react-router-dom';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { IModules } from '../dashboard/dashboard';
import { addYears } from '../../utils/date-picker/util-time';
import { IError } from '@vva/front/api-client/interfaces';
import DropDownCountries from '../../utils/dropdown-front/drop-down-countries';
import FilterableDropdownList from '../../utils/dropdown-front/filterable-drop-down-list';

export interface IUserAccount {
  fullName?: string;
  email?: string;
  internetEmail?: string;
  partnerId?: string;
  id?: string;
  countryId?: string;
  countryName?: string;
  createdAt?: string;
  updatedAt?: string;
  password?: string;
  status?: EnumAccountStatus;
  oldEmail?: string;
  accountType?: string;
  expirationDate?: string;
  master?: string;
  proPlan?: string;
  proUser?: EnumProUserStatus;
  partnerName?: string;
  proExpiration?: Date;
}

interface IPassword {
  newPassword: string;
  confirmNewPassword: string;
}

const CreateEditAccount: React.FC = () => {
  const history = useHistory();
  const config = useConfig();
  const { handleLogoutState } = useAuth();
  const [showModalWindow, setShowModalWindow] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [countries, setCountries] = useState<any>();
  const [countryInfo, setCountryInfo] = useState<any>();
  const [showPasswordRequirements, setShowPasswordRequirements] = useState<boolean>(false);
  const [showCustomDropDown, setShowCustomDropDown] = useState<boolean>(false);
  const [password, setPassword] = useState<IPassword>({
    newPassword: '',
    confirmNewPassword: '',
  });
  const [accountUserData, setAccountUserData] = useState<IUserAccount>({
    proPlan: EnumProPlan.ProPlanYearly,
    proExpiration: addYears(10),
  });
  const [ButtonDisableOnPasswordHandling, setButtonDisableOnPasswordHandling] = useState<boolean>(false);
  const [validLength, hasNumber, upperCase, specialChar, match] = usePasswordValidation({
    firstPassword: password.newPassword,
    secondPassword: password.confirmNewPassword,
    requiredLength: 8,
    numberValidation: true,
    lengthValidation: true,
    uppercaseValidation: true,
    specialCharacterValidation: true,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [master, setMaster] = useState<boolean>(false);
  const [displayAdmin, setDisplayAdmin] = useState<boolean>(true);
  const [adminsNames, setAdminsNames] = useState<any>();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [accountToEdit, setAccountToEdit] = useState<IUserAccount>();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('userId');
    if (userId) {
      getUserInfo(userId);
    }
  }, []);

  const getUserInfo = async (userId: string) => {
    try {
      const getUser = (await getUserById(userId)) as any;
      setAccountToEdit(getUser);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  useEffect(() => {
    if (accountToEdit && accountToEdit.id) {
      setAccountUserData(accountToEdit);
    }
  }, [accountToEdit]);

  useEffect(() => {
    getAdmin();
  }, []);

  const getAdmin = async () => {
    try {
      const responseData = await searchPartnerByKeyword('');
      setAdminsNames(responseData);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  useEffect(() => {
    if (accountToEdit && accountToEdit.id) {
      if (countries && accountToEdit) {
        const userCountryId = accountToEdit.countryId || 'GB';
        const userCountryName = countries && countries.find((country: any) => country.country === userCountryId);
        setCountryInfo({
          ...countryInfo,
          countryName: userCountryName.name || '',
          countryId: userCountryName.country || '',
        });
      }
    } else {
      if (countries) {
        const userCountryId = (accountUserData && accountUserData.countryId) || 'GB';
        const userCountryName = countries && countries.find((country: any) => country.country === userCountryId);
        setCountryInfo({
          ...countryInfo,
          countryName: userCountryName.name || '',
          countryId: userCountryName.country || '',
        });
      }
    }
  }, [countries, accountToEdit]);

  useEffect(() => {
    let userData: IUserAccount = {};
    if (config && config.countries && config.countryId) {
      setCountries(config.countries);
      userData.countryId = config.countryId;

      const mastersTab = config.modules.find((a: IModules) => Object.keys(a)[0] === 'masterTab')?.masterTab;
      if (!mastersTab) {
        const admin = adminsNames?.find((a: any) => a.id === config.partnerId);
        userData.partnerId = admin?.id;
        userData.partnerName = admin?.name;
      }
      setMaster(mastersTab);

      if (window.location.pathname.includes('create-admin')) {
        setDisplayAdmin(true);
      } else {
        setDisplayAdmin(false);
      }
    }
    if (accountToEdit && accountToEdit.id) {
      if (accountToEdit && countryInfo) {
        userData = { ...userData };
        userData = { ...accountToEdit, ...countryInfo };
      }
    } else {
      if (countryInfo && countryInfo.countryName !== '') {
        userData.countryId = countryInfo.countryId;
        userData.countryName = countryInfo.countryName;
      }
    }
    setAccountUserData({ ...accountUserData, ...userData });
  }, [config, accountToEdit, countryInfo, adminsNames]);

  useEffect(() => {
    setButtonDisableOnPasswordHandling(!checkPasswordParameters(validLength, hasNumber, upperCase, specialChar, match) ? true : false);
  }, [validLength, hasNumber, upperCase, specialChar, match]);

  const checkIfNewAccountShouldBePro = () => {
    const rescomAdminId = '9BA50525-952E-4860-8146-6E2D9429BA8A';
    const kellerWilliams = 'B650B675-CA82-4B8E-A60E-1ADBA3736236';
    if (config?.partnerId === kellerWilliams || rescomAdminId) {
      return true;
    } else {
      return false;
    }
  };

  const checkPasswordParameters = (
    validLength: boolean,
    hasNumber: boolean,
    upperCase: boolean,
    hasSpecialChar: boolean,
    match: boolean,
  ) => {
    const isValid = (currentValue: boolean) => currentValue === true;
    const params = [validLength, hasNumber, upperCase, hasSpecialChar, match];
    return params.every(isValid);
  };

  const showModal = (action: string) => {
    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.DeleteAccount);
    setAction(action);
    setShowModalWindow(true);
  };

  const hideModal = () => {
    setShowModalWindow(false);
  };

  const buttonDisabled = () => {
    if (password.newPassword !== '' || password.confirmNewPassword !== '') {
      return ButtonDisableOnPasswordHandling;
    } else {
      return false;
    }
  };

  const handlerSelectItem = async (selectedValue: any) => {
    if (selectedValue && selectedValue.length) {
      setAccountUserData({ ...accountUserData, countryId: selectedValue[0].country, countryName: selectedValue[0].name });
    } else {
      setAccountUserData({ ...accountUserData, countryId: '', countryName: '' });
    }
  };

  const handlerChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
    setAccountUserData({ ...accountUserData, password: e.target.value });
  };

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    if (type === EnumNotificationType.Error) {
      setError(!error);
    }
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const handlerInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountUserData({ ...accountUserData, [e.target.name]: e.target.value });
    if (error && accountUserData?.email && accountUserData.email !== '') {
      toggleNotification(EnumNotificationType.Info, linguiTranslateToString('No data to display for the selected time frame'));
    }
  };

  const handlerStatusAccount = async (accountStatus: string) => {
    let tempAccountUserData = { ...accountUserData };
    let message;
    if (accountStatus === 'disable') {
      message = 'The user has been disabled';
      tempAccountUserData.status = 2;
    } else if (accountStatus === 'reactivate') {
      message = 'The user has been reactivate';
      tempAccountUserData.status = 1;
    } else if (accountStatus === 'delete') {
      message = 'The user has been delete';
      tempAccountUserData.status = -1;
    }
    setAccountUserData(tempAccountUserData);
    await handlerUpdateAccount(tempAccountUserData);
    setShowModalWindow(false);
    toggleNotification(EnumNotificationType.Success, message);
  };

  const handlerUpdateAccount = async (accountUser?: IUserAccount) => {
    const userData = accountUser ? accountUser : accountUserData;
    try {
      await updateUserAccountById(accountToEdit?.id || '', userData);
      toggleNotification(EnumNotificationType.Success, 'User was successfully updated');
    } catch (error) {
      const err = error as IError;
      toggleNotification(EnumNotificationType.Error, err.statusText || 'There was an error please try again');
      handleError(err, handleLogoutState);
    }
  };

  const handlerCreateNewAccount = async () => {
    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.CreateAccount);
    try {
      await createAccount(accountUserData);
      toggleNotification(EnumNotificationType.Success, 'The account has been created');
      setTimeout(() => {
        history.push('admin-management');
      }, 1500);
    } catch (error: any) {
      const message = error.error.data.status.message || 'There was an error please try again';
      toggleNotification(EnumNotificationType.Error, message);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const onClickDisable = () => {
    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.DisableAccount);
    if (accountUserData?.status && accountUserData.status === 1) {
      showModal('disable');
    } else {
      showModal('reactivate');
    }
  };

  const onChangeDate = (timestamp: string | number) => {
    const newDate = new Date(timestamp);
    setAccountUserData({ ...accountUserData, proExpiration: newDate });
  };

  const onChangeAccountType = (option: string) => {
    const optionArr = typeProPlan.find(a => a.value === option);
    if (optionArr) {
      setAccountUserData({ ...accountUserData, proPlan: optionArr.id, proUser: optionArr.proUser });
      setShowCustomDropDown(false);
    }
  };

  const toggleCustomDropdown = (option?: boolean) => {
    if (option) {
      setShowCustomDropDown(option);
    } else {
      setShowCustomDropDown(!showCustomDropDown);
    }
  };

  const handleSelectedPartnerItem = async (selectedValue: any) => {
    if (selectedValue) {
      setAccountUserData({ ...accountUserData, partnerId: selectedValue[0].id, partnerName: selectedValue[0].name });
    }
  };

  const pwdShouldContainSection = (
    <div className='cea-pwd-should-contain-section'>
      <div className='cea-pwd-should-contain-title'>
        <h3>
          <Trans id='Your password should be' />
        </h3>
      </div>
      <div className='cea-pwd-should-contain-items-container'>
        <div className='cea-pwd-should-contain-item'>
          <img className='cea-pwd-check-status' src={!validLength ? cross : tick} alt='Icon tick or cross' />
          <span className='cea-pwd-check-text'>
            <Trans id='At least 8 characters' />
          </span>
        </div>
        <div className='cea-pwd-should-contain-item'>
          <img className='cea-pwd-check-status' src={!upperCase ? cross : tick} alt='Icon tick or cross' />
          <span className='cea-pwd-check-text'>
            <Trans id='A capital letter' />
          </span>
        </div>
        <div className='cea-pwd-should-contain-item'>
          <img className='cea-pwd-check-status' src={!hasNumber ? cross : tick} alt='Icon tick or cross' />
          <span className='cea-pwd-check-text'>
            <Trans id='A number' />
          </span>
        </div>
        <div className='cea-pwd-should-contain-item'>
          <img className='cea-pwd-check-status' src={!specialChar ? cross : tick} alt='Icon tick or cross' />
          <span className='cea-pwd-check-text'>
            <Trans id='Contain a special character' /> <i>e.g. [!@£$%^&*()]</i>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <NavSidebar />
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      <div className='dashboard-layout'>
        <div className='dashboard'>
          <div className='content-wrapper-account-management'>
            <div className='AM-data-container'>
              <div className='cea-content-wrapper'>
                {showModalWindow && (
                  <ModalAccountManagement
                    hideModal={hideModal}
                    modalAction={action}
                    statusAccount={handlerStatusAccount}
                    email={accountUserData?.email || ''}
                  />
                )}
                <div className='prev-page-container'>
                  <Link
                    to={{
                      pathname: `/users-management`,
                    }}
                  >
                    <Trans
                      id='Back to previous page'
                      render={({ translation }) => (
                        <button className='prev-page-link'>
                          <img src={backArrow} alt='Go back arrow' />
                          {translation}
                        </button>
                      )}
                    />
                  </Link>
                </div>
                <div className='cea-general-container'>
                  <div className='ca-content'>
                    <div className='container-general'>
                      <div className='inputs-container'>
                        {displayAdmin ? (
                          <>
                            <div className='input-container-row'>
                              <div id='ca-name-email' className='field-container'>
                                <Trans
                                  id='Name'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='fullName'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={`general-field-input-text ${
                                    accountUserData?.status && accountUserData.status === 2
                                      ? 'input-disable'
                                      : accountUserData?.fullName && accountUserData.fullName !== ''
                                      ? 'valid-input'
                                      : ''
                                  }`}
                                  type='text'
                                  id='fullName'
                                  name='fullName'
                                  value={accountUserData?.fullName || ''}
                                  onChange={handlerInputValue}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                              </div>
                              <div className='field-container'>
                                <Trans
                                  id='Communications Email Address'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='email'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className='general-field-input-text'
                                  type='text'
                                  id='internetEmail'
                                  name='internetEmail'
                                  value={accountUserData?.internetEmail || ''}
                                  onChange={handlerInputValue}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                              </div>
                              <div className='field-container'>
                                <Trans
                                  id='Email'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='email'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={`general-field-input-text ${
                                    accountUserData?.status && accountUserData.status === 2
                                      ? 'input-disable'
                                      : error
                                      ? 'input-wrong'
                                      : accountUserData?.email && accountUserData?.email !== ''
                                      ? 'valid-input'
                                      : ''
                                  }`}
                                  type='text'
                                  id='email'
                                  name='email'
                                  value={accountUserData?.email || ''}
                                  onChange={handlerInputValue}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                              </div>
                            </div>
                            <div className='input-container-row'>
                              <div id='ca-countries' className='field-container select-input'>
                                <Trans
                                  id='Country'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <div className='cea-countries-container'>
                                  {accountToEdit && accountToEdit.id ? (
                                    <DropDownCountries
                                      selectedProps={
                                        accountUserData && accountUserData.countryName && accountUserData.countryName !== ''
                                          ? [accountUserData.countryName]
                                          : ['']
                                      }
                                      dataset={countries ? countries : []}
                                      allowNew={false}
                                      isDisabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                      labelKey={'name'}
                                      handleSelectedItem={handlerSelectItem}
                                      IsResultHighlightOnly={false}
                                      placeHolderValue=''
                                    />
                                  ) : (
                                    <DropDownCountries
                                      selectedProps={
                                        accountUserData && accountUserData.countryName && accountUserData.countryName !== ''
                                          ? [accountUserData.countryName]
                                          : ['']
                                      }
                                      dataset={countries ? countries : []}
                                      allowNew={false}
                                      isDisabled={false}
                                      labelKey={'name'}
                                      handleSelectedItem={handlerSelectItem}
                                      IsResultHighlightOnly={false}
                                      placeHolderValue=''
                                    />
                                  )}
                                  <img className='cea-arrow-country-dropdown' src={selectArrow} alt='Drop down menu arrow country' />
                                </div>
                              </div>
                              <div id='ca-password' className='field-container'>
                                <Trans
                                  id='Password'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='newPassword'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={` general-field-input-text ${
                                    accountUserData?.status && accountUserData.status === 2
                                      ? 'input-disable'
                                      : password.newPassword !== ''
                                      ? 'valid-input'
                                      : ''
                                  }`}
                                  type={showPassword ? 'text' : 'password'}
                                  name='newPassword'
                                  id='newPassword'
                                  value={password.newPassword}
                                  onChange={handlerChangePassword}
                                  onFocus={() => setShowPasswordRequirements(true)}
                                  onBlur={() => setShowPasswordRequirements(false)}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                                <button
                                  id='show-newPassword'
                                  onClick={(e: React.MouseEvent): void => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                  }}
                                  className='field-show-password-icon'
                                >
                                  <img src={showPassword ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                                </button>
                              </div>
                              <div className='field-container'>
                                <Trans
                                  id='Confirm password'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='confirmNewPassword'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={` general-field-input-text ${
                                    accountUserData?.status && accountUserData.status === 2
                                      ? 'input-disable'
                                      : password.confirmNewPassword === ''
                                      ? ''
                                      : !match
                                      ? 'input-wrong'
                                      : 'valid-input'
                                  }`}
                                  type={showConfirmPassword ? 'text' : 'password'}
                                  name='confirmNewPassword'
                                  id='confirmNewPassword'
                                  value={password.confirmNewPassword}
                                  onChange={handlerChangePassword}
                                  onFocus={() => setShowPasswordRequirements(true)}
                                  onBlur={() => setShowPasswordRequirements(false)}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                                <button
                                  id='show-confirmNewPassword'
                                  onClick={(e: React.MouseEvent): void => {
                                    e.preventDefault();
                                    setShowConfirmPassword(!showConfirmPassword);
                                  }}
                                  className='field-show-password-icon'
                                >
                                  <img src={showConfirmPassword ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                                </button>
                                {showPasswordRequirements && pwdShouldContainSection}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='input-container-row'>
                              <div id='ca-master' className='field-container'>
                                <Trans
                                  id='Master'
                                  render={({ translation }) => (
                                    <label
                                      className={!master ? 'create-tour-input-labels-disable' : 'create-tour-input-labels'}
                                      htmlFor='Master'
                                    >
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <div className={!master ? 'master-input-disable' : 'master-input'}>
                                  <FilterableDropdownList
                                    selectedProps={accountUserData?.partnerName ? [accountUserData.partnerName] : ['']}
                                    dataset={adminsNames ? adminsNames : []}
                                    allowNew={false}
                                    isDisabled={!master}
                                    labelKey={'name'}
                                    handleSelectedItem={handleSelectedPartnerItem}
                                    IsResultHighlightOnly={false}
                                  />
                                  <img
                                    className={!master ? 'arrow-filter-disabled' : 'arrow-filter'}
                                    alt='arrow'
                                    src={!master ? arrowDisabled : arrow}
                                  />
                                </div>
                              </div>
                              <div id='ca-name-email' className='field-container'>
                                <Trans
                                  id='Name'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='fullName'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={`general-field-input-text ${
                                    accountUserData?.status && accountUserData.status === 2
                                      ? 'input-disable'
                                      : accountUserData?.fullName && accountUserData.fullName !== ''
                                      ? 'valid-input'
                                      : ''
                                  }`}
                                  type='text'
                                  id='fullName'
                                  name='fullName'
                                  value={accountUserData?.fullName || ''}
                                  onChange={handlerInputValue}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                              </div>
                              <div className='field-container'>
                                <Trans
                                  id='Communications Email Address'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='email'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={`general-field-input-text ${
                                    accountUserData?.internetEmail && accountUserData?.internetEmail !== '' ? 'valid-input' : ''
                                  }`}
                                  type='text'
                                  id='internetEmail'
                                  name='internetEmail'
                                  value={accountUserData?.internetEmail || ''}
                                  onChange={handlerInputValue}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                              </div>
                            </div>

                            <div className='input-container-row'>
                              <div className='field-container'>
                                <Trans
                                  id='Email'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='email'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={`general-field-input-text ${
                                    accountUserData?.status && accountUserData.status === 2
                                      ? 'input-disable'
                                      : error
                                      ? 'input-wrong'
                                      : accountUserData?.email && accountUserData?.email !== ''
                                      ? 'valid-input'
                                      : ''
                                  }`}
                                  type='text'
                                  id='email'
                                  name='email'
                                  value={accountUserData?.email || ''}
                                  onChange={handlerInputValue}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                              </div>
                              <div id='ca-countries' className='field-container select-input'>
                                <Trans
                                  id='Country'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <div className='cea-countries-container'>
                                  {accountToEdit && accountToEdit.id ? (
                                    <DropDownCountries
                                      selectedProps={
                                        accountUserData && accountUserData.countryName && accountUserData.countryName !== ''
                                          ? [accountUserData.countryName]
                                          : ['']
                                      }
                                      dataset={countries ? countries : []}
                                      allowNew={false}
                                      isDisabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                      labelKey={'name'}
                                      handleSelectedItem={handlerSelectItem}
                                      IsResultHighlightOnly={false}
                                      placeHolderValue=''
                                    />
                                  ) : (
                                    <DropDownCountries
                                      selectedProps={
                                        accountUserData && accountUserData.countryName && accountUserData.countryName !== ''
                                          ? [accountUserData.countryName]
                                          : ['']
                                      }
                                      dataset={countries ? countries : []}
                                      allowNew={false}
                                      isDisabled={false}
                                      labelKey={'name'}
                                      handleSelectedItem={handlerSelectItem}
                                      IsResultHighlightOnly={false}
                                      placeHolderValue=''
                                    />
                                  )}
                                  <img className='cea-arrow-country-dropdown' src={selectArrow} alt='Drop down menu arrow country' />
                                </div>
                              </div>
                              <div id='ca-master' className='field-container' style={{ zIndex: '1' }}>
                                <Trans
                                  id='Account Type'
                                  render={({ translation }) => (
                                    <label
                                      className={`create-tour-input-labels-disable ${
                                        showCustomDropDown ? 'create-tour-input-labels-focus' : ''
                                      }`}
                                      htmlFor='Master'
                                    >
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                {checkIfNewAccountShouldBePro() ? (
                                  <CustomSelect
                                    master={true}
                                    value={typeProPlan.find(option => option.id === accountUserData?.proPlan)?.value || ''}
                                    options={[]}
                                    changeDropdownValue={onChangeAccountType}
                                    showCustomDropDown={showCustomDropDown}
                                    setShowCustomDropDown={toggleCustomDropdown}
                                    customArrow='arrow-filter-disabled'
                                    arrowStyle={arrowDisabled}
                                    customClass={`general-field-disable ${showCustomDropDown ? 'general-field-focus' : ''}`}
                                    disableArrow={true}
                                  />
                                ) : (
                                  <CustomSelect
                                    master={true}
                                    value={typeProPlan.find(option => option.id === accountUserData?.proPlan)?.value || ''}
                                    options={
                                      master
                                        ? typeProPlan.map(option => defineMessage({ message: option.value }))
                                        : typeProPlanNotMaster.map(option => defineMessage({ message: option.value }))
                                    }
                                    changeDropdownValue={onChangeAccountType}
                                    showCustomDropDown={showCustomDropDown}
                                    setShowCustomDropDown={toggleCustomDropdown}
                                    customArrow='arrow-filter'
                                    arrowStyle={arrow}
                                    customClass={`general-field-input ${showCustomDropDown ? 'general-field-focus' : ''}`}
                                  />
                                )}
                              </div>
                            </div>
                            <div className='input-container-row'>
                              <div id='ca-master' className='field-container' style={{ cursor: !master ? 'default' : 'pointer' }}>
                                <Trans
                                  id='Expiration date'
                                  render={({ translation }) => (
                                    <label
                                      className={`${
                                        showDatePicker
                                          ? 'create-tour-input-labels-focus'
                                          : !master
                                          ? 'create-tour-input-labels-disable'
                                          : 'create-tour-input-labels'
                                      }`}
                                      htmlFor='Master'
                                    >
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <DatePicker
                                  master={master}
                                  accountUserData={accountUserData}
                                  setAccountUserData={setAccountUserData}
                                  onChange={onChangeDate}
                                  customDate={accountUserData?.proExpiration}
                                  setShowDatePicker={setShowDatePicker}
                                  showDatePicker={showDatePicker}
                                />
                              </div>
                              <div id='ca-password' className='field-container'>
                                <Trans
                                  id='Password'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='newPassword'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={` general-field-input-text ${
                                    accountUserData?.status && accountUserData.status === 2
                                      ? 'input-disable'
                                      : password.newPassword === ''
                                      ? ''
                                      : !match
                                      ? 'input-wrong'
                                      : 'valid-input'
                                  }`}
                                  type={showPassword ? 'text' : 'password'}
                                  name='newPassword'
                                  id='newPassword'
                                  value={password.newPassword}
                                  onChange={handlerChangePassword}
                                  onFocus={() => setShowPasswordRequirements(true)}
                                  onBlur={() => setShowPasswordRequirements(false)}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                                <button
                                  id='show-newPassword'
                                  onClick={(e: React.MouseEvent): void => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                  }}
                                  className='field-show-password-icon'
                                >
                                  <img src={showPassword ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                                </button>
                              </div>
                              <div className='field-container'>
                                <Trans
                                  id='Confirm password'
                                  render={({ translation }) => (
                                    <label className='create-tour-input-labels' htmlFor='confirmNewPassword'>
                                      <b>{translation}</b>
                                    </label>
                                  )}
                                />
                                <input
                                  className={` general-field-input-text ${
                                    accountUserData?.status && accountUserData.status === 2
                                      ? 'input-disable'
                                      : password.confirmNewPassword === ''
                                      ? 'input-default'
                                      : !match
                                      ? 'input-wrong'
                                      : 'valid-input'
                                  }`}
                                  type={showConfirmPassword ? 'text' : 'password'}
                                  name='confirmNewPassword'
                                  id='confirmNewPassword'
                                  value={password.confirmNewPassword}
                                  onChange={handlerChangePassword}
                                  onFocus={() => setShowPasswordRequirements(true)}
                                  onBlur={() => setShowPasswordRequirements(false)}
                                  disabled={accountUserData?.status && accountUserData.status === 2 ? true : false}
                                />
                                <button
                                  id='show-confirmNewPassword'
                                  onClick={(e: React.MouseEvent): void => {
                                    e.preventDefault();
                                    setShowConfirmPassword(!showConfirmPassword);
                                  }}
                                  className='field-show-password-icon'
                                >
                                  <img src={showConfirmPassword ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                                </button>
                                {showPasswordRequirements && pwdShouldContainSection}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='container-options-admin'>
                      {accountToEdit && accountToEdit.id && (
                        <div className='disable'>
                          {accountUserData && accountUserData.status === 1 ? (
                            <div className='title-disable-reactivate'>
                              <Trans id='Disable account' />
                            </div>
                          ) : (
                            <div className='title-disable-reactivate'>
                              <Trans id='Reactivate account' />
                            </div>
                          )}

                          <div className='disable-delete-options-container-admin'>
                            <div className='disable-delete-btn-icon'>
                              <button
                                className={
                                  accountUserData?.status && accountUserData.status === 1 ? 'btn-status-disabled' : 'btn-status-active'
                                }
                                onClick={() => {
                                  onClickDisable();
                                }}
                              ></button>
                            </div>
                            <div id='ea-disable' className='disable-delete-options'>
                              {accountUserData && accountUserData.status === EnumAccountStatus.Active ? (
                                <div className='disable-btn-container'>
                                  <div>
                                    <p className='ea-text'>
                                      <Trans id="The account will be deactivated and user won't be able to access the account anymore." />
                                    </p>
                                    <p className='ea-text'>
                                      <Trans id='You can reactivate the account anytime.' />
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className='disable-btn-container'>
                                  <div>
                                    <p className='ea-text'>
                                      <Trans id='The account will be reactivated and user will have access to the tours.' />
                                    </p>
                                    <p className='ea-text'>
                                      <Trans id='You can disable the account again anytime.' />
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {accountToEdit && accountToEdit.id && (
                        <div className='disable'>
                          <div className='title-disable-reactivate'>
                            <Trans id='Delete account' />
                          </div>
                          <div id='ea-delete' className='disable-delete-options-container-admin'>
                            <div className='disable-delete-btn-icon'>
                              <button className='btn-status-delete' onClick={() => showModal('delete')}></button>
                            </div>
                            <div className='delete-btn-container'>
                              <div>
                                <p className='ea-text'>
                                  <Trans id='The account and the tours will be deleted. Deleting an account is permanent and cannot be recovered.' />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {accountToEdit && accountToEdit.id ? (
                      <div className='save-account-btn-container'>
                        <button
                          className={`${buttonDisabled() ? 'btn-save-invalid' : 'btn-save button-hover'}`}
                          onClick={() => handlerUpdateAccount()}
                          disabled={buttonDisabled() ? true : false}
                        >
                          <Trans id='Save Changes' />
                        </button>
                      </div>
                    ) : (
                      <div className='create-account-btn-container'>
                        <button
                          className={`${
                            !match ||
                            (accountUserData?.fullName && accountUserData.fullName === '') ||
                            (accountUserData?.email && accountUserData.email === '')
                              ? 'btn-create-invalid'
                              : 'btn-create button-hover'
                          }`}
                          onClick={handlerCreateNewAccount}
                          disabled={buttonDisabled() ? true : false}
                        >
                          <Trans id='Create account' />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEditAccount;
