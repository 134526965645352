import "../scss//viewer.scss";
import "../scss/pannellum.scss";
import pannellum from "../js/pannellum";
import { IViewerConfig } from "../interfaces/IViewerConfig";
import { IHotspot } from "../interfaces/IHotspot";
import { IPannellumViewer } from "../interfaces/IPannellumViewer";
import { IViewer } from "../interfaces/IViewer";

class ViewerImpl implements IViewer {
    /**
     * Viewer
     */
    private viewer!: IPannellumViewer;

    /**
     * Viewer constructor
     * @param container
     * @param config
     */
    public constructor(container: HTMLElement | string, config: IViewerConfig) {
        this.initViewer(container, config);
    }

    /**
     * Get viewer
     * @returns viewer object
     */
    public getViewer(): IPannellumViewer {
        return this.viewer;
    }

    /**
     * Set viewer
     * @param {IPannellumViewer}
     */
    public setViewer(viewer: IPannellumViewer): void {
        this.viewer = viewer;
    }

    /**
     * Checks whether or not a panorama is loaded.
     * @returns boolean true if a panorama is loaded, else false
     */
    public isLoaded(): boolean {
        return this.viewer && this.viewer.isLoaded();
    }

    /**
     * Initialize viewer
     * @param container
     * @param config
     */
    public initViewer(
        container: HTMLElement | string,
        config: IViewerConfig
    ): void {
        this.setViewer(pannellum.viewer(container, config));
    }

    /**
     * Subscribe listener to specified event or Remove an event listener
     * @param method - Listener function to subscribe to event.
     * @param enable - Subscribe or remove listener
     */
    public setOnMousedown(method: any, enable: boolean): void {
        if (enable) {
            this.viewer &&
                this.viewer.on("mousedown", (event: any) => {
                    const coordinates = this.viewer.mouseEventToCoords(event);

                    method(coordinates);
                });
        } else {
            this.viewer.off("mousedown");
        }
    }

    /**
     * Subscribe listener to specified event or Remove an event listener
     * @param method - Listener function to subscribe to event.
     * @param enable - Subscribe or remove listener
     */
    public setOnDoubleClick(method: any, enable: boolean): void {
        if (enable) {
            this.viewer.on("dblclick", (event: any) => {
                const coordinates = this.viewer.mouseEventToCoords(event);

                method(coordinates);
            });
        } else {
            this.viewer.off("dblclick");
        }
    }

    /**
     * Add new hotspot
     * @param {object} hotspot - The configuration for the hot spot
     */
    public addNewHotspot(hotspot: IHotspot): void {
        this.viewer.addHotSpot(hotspot);
    }

    /**
     * Remove hotspot
     * @param {object} hotspot
     */
    public removeCurrentHotspot(hotspot: IHotspot): void {
        this.viewer.removeHotSpot(hotspot);
    }

    /**
     * Change scene being viewed
     * @param sceneId string Identifier of scene to switch to.
     * @param pitch number? Pitch to use with new scene
     * @param yaw number? Yaw to use with new scene
     * @param hfov number? HFOV to use with new scene
     */
    public changeScene(
        id: string,
        pitch?: number,
        yaw?: number,
        hfov?: number
    ): void {
        this.viewer.loadScene(id, pitch, yaw, hfov);
    }

    /**
     * Subscribe listener to load event.
     * @param method
     */
    public setOnLoad(method: any): void {
        this.viewer.on("load", method);
    }

    /**
     * Subscribe listener on scene change event.
     * @param method
     */
    public setOnScenechange(method: any): void {
        this.viewer.on("scenechange", method);
    }

    /**
     * Subscribe listener on scene change fade done event.
     * @param method
     */
    public setOnScenechangefadedone(method: any): void {
        this.viewer.on("scenechangefadedone", method);
    }

    /**
     * Subscribe listener on error event.
     * @param method
     */
    public setOnError(method: any): void {
        this.viewer.on("error", method);
    }

    /**
     * Subscribe listener on error cleared
     * @param method
     */
    public setOnErrorcleared(method: any): void {
        this.viewer.on("errorcleared", method);
    }

    /**
     * Subscribe listener to mouseup event
     * @param method
     */
    public setOnMouseup(method: any): void {
        this.viewer.on("mouseup", method);
    }

    /**
     * Subscribe listener to touchstart event
     * @param method
     */
    public setOnTouchstart(method: any): void {
        this.viewer.on("touchstart", method);
    }

    /**
     * Subscribe listener to touchend event
     * @param method
     */
    public setOnTouchend(method: any): void {
        this.viewer.on("touchend", method);
    }

    /**
     * Subscribe listener on panorama load event
     * @param method
     */
    public onPanoramaLoaded(isLoaded: boolean): void {
        this.viewer.off("load", isLoaded);
    }

    /**
     * Remove a scene
     * @param {string} sceneId - The ID of the scene
     */
    public removeScene(id: string): void {
        this.viewer.removeScene(id);
    }

    /**
     * Destructor - Destroy viewer
     * @param method
     */
    public destroyViewer() {
        return this.viewer && this.viewer.destroy();
    }

    /**
     * Set yaw
     * @param degrees number Yaw in degrees [-180, 180]
     * @param animated (boolean | number) Animation duration in milliseconds or false for no animation (optional, default 1000)
     * @param callback  function? Function to call when animation finishes
     * @param callbackArgs object? Arguments to pass to callback function
     */
    public setYaw(
        degrees: number,
        animated?: boolean | number,
        callback?: Function,
        callbackArgs?: object
    ): void {
        this.viewer.setYaw(degrees, animated, callback, callbackArgs);
    }

    /**
     * Returns the yaw of the center of the view.
     * @returns {number} Yaw in degrees
     */
    public getYaw(): number {
        return this.viewer && this.viewer.getYaw();
    }

    /**
     * Set pitch
     * @param degrees number Pitch in degrees
     * @param animated (boolean | number) Animation duration in milliseconds or false for no animation (optional, default 1000)
     * @param callback  function? Function to call when animation finishes
     * @param callbackArgs object? Arguments to pass to callback function
     */
    public setPitch(
        degrees: number,
        animated?: boolean | number,
        callback?: Function,
        callbackArgs?: object
    ): void {
        this.viewer.setPitch(degrees, animated, callback, callbackArgs);
    }
    /**
     * Returns the pitch of the center of the view.
     * @returns {number} Pitch in degrees
     */
    public getPitch(): number {
        return this.viewer && this.viewer.getPitch();
    }

    /**
     * Returns the horizontal field of view.
     * @returns {number} Horizontal field of view in degrees
     */
    public getHorizontalFieldOfView(): number {
        return this.viewer && this.viewer.getHfov();
    }

    /**
     *
     * @param degrees number Horizontal field of view in degrees
     * @param animated (boolean | number) Animation duration in milliseconds or false for no animation (optional, default 1000)
     * @param callback  function? Function to call when animation finishes
     * @param callbackArgs  object? Arguments to pass to callback function
     */
    public setHorizontalFieldOfView(
        degrees: number,
        animated?: boolean | number,
        callback?: Function,
        callbackArgs?: object
    ): void {
        this.viewer.setHfov(degrees, animated, callback, callbackArgs);
    }

    /**
     * Returns the minimum and maximum allowed horizontal fields of view (in degrees).
     * @returns {Array<number>} , [minimum hfov, maximum hfov].
     */
    public getHorizontalFieldOfViewBounds(): Array<number> {
        return this.viewer && this.viewer.getHfovBounds();
    }

    /**
     * Returns the minimum and maximum allowed horizontal fields of view (in degrees).
     * @returns {Array<number>} , [minimum hfov, maximum hfov].
     */
    public setHorizontalFieldOfViewBounds(bounds: Array<number>): void {
        this.viewer.setHfovBounds(bounds);
    }

    /**
     * Returns the pitch, yaw of the center of the view and the horizontal field of view.
     * @returns {Array<number>}  Pitch in degrees, Yaw in degrees, and Horizontal field of view in degrees
     */
    public getCoordinates(): Array<number> {
        const yaw = this.viewer.getYaw();
        const pitch = this.viewer.getPitch();
        const hfvo = this.viewer.getHfov();
        return [yaw, pitch, hfvo];
    }

    /**
     * Get ID of current scene
     * @returns
     */
    public getScene(sceneId: string) {
        return this.viewer && this.viewer.getScene(sceneId);
    }

    /**
     * Returns object with sceneId and current scene config.
     * @returns
     */
    public getCurrentScene() {
        return this.viewer && this.viewer.getScene();
    }

    /**
     * Start auto rotation
     * @param  {number} speed - Auto rotation speed / direction. If not specified, previous value is used.
     * @param {number} pitch - The pitch to rotate at. If not specified, initial pitch is used.
     */
    public startAutoRotate(speed: number, pitch: number) {
        return this.viewer && this.viewer.startAutoRotate(speed, pitch);
    }

    /**
     * Stop auto rotation
     */
    public stopAutoRotate() {
        if (this.viewer) {
            this.viewer.stopAutoRotate();
        }
    }

    /**
     * Check if device orientation control is supported.
     * @returns boolean True if supported, else false
     */
    public isOrientationSupported(): boolean {
        return this.viewer && this.viewer.isOrientationSupported();
    }

    /**
     * Check if device orientation control is currently activated. - Returns boolean True if active, else false
     * @returns boolean True if active, else false
     */
    public isOrientationActive(): boolean {
        return this.viewer && this.viewer.isOrientationActive();
    }

    /**
     * Stop using device orientation.
     */
    public stopOrientation(): void {
        return this.viewer && this.viewer.stopOrientation();
    }

    /**
     * Start using device orientation (does nothing if not supported).
     */
    public startOrientation(): void {
        return this.viewer && this.viewer.startOrientation();
    }

    /**
     * Returns the panorama’s north offset.
     * @returns {number} North offset in degrees
     */
    public getNorthOffset(): number {
        return this.viewer && this.viewer.getNorthOffset();
    }

    /**
     * Get configuration of current scene.
     * @returns {Object} Configuration of current scene
     */
    public getConfig(): void {
        return this.viewer && this.viewer.getConfig();
    }

    /**
     * Set a new view. Any parameters not specified remain the same.
     * @param pitch {number} Target pitch
     * @param yaw {number} Target yaw
     * @param hfov {number} Target hfov
     * @param animated (boolean | number) Animation duration in milliseconds or false for no animation (optional, default 1000)
     * @param callback  {function} Function to call when animation finishes
     * @param callbackArgs {object} Arguments to pass to callback function
     */
    public lookAt(
        pitch: number,
        yaw: number,
        hfov: number,
        animated: number | boolean,
        callback?: Function,
        callbackArgs?: any
    ): void {
        return (
            this.viewer &&
            this.viewer.lookAt(
                pitch,
                yaw,
                hfov,
                animated,
                callback,
                callbackArgs
            )
        );
    }

    /**
     * This method should be called if the viewer’s container is resized.
     */
    public resize(): void {
        return this.viewer && this.viewer.resize();
    }

    /**
     * Toolbar commands ----------------------------------------------------------------------------------------------------------------------------------------------------
     */

    /**
     * pan-up
     * @param degrees
     */
    public panUp(degrees: number): void {
        this.viewer.setPitch(this.viewer.getPitch() + degrees);
    }

    /**
     * pan-down
     * @param degrees
     */
    public panDown(degrees: number): void {
        this.viewer.setPitch(this.viewer.getPitch() - degrees);
    }

    /**
     * pan-left
     * @param degrees
     */
    public panLeft(degrees: number): void {
        this.viewer.setYaw(this.viewer.getYaw() - degrees);
    }

    /**
     * pan-right
     * @param degrees
     */
    public panRight(degrees: number): void {
        this.viewer.setYaw(this.viewer.getYaw() + degrees);
    }

    /**
     * zoom-in
     * @param degrees
     */
    public zoomIn(degrees: number): void {
        this.viewer.setHfov(this.viewer.getHfov() - degrees);
    }

    /**
     * zoom-out
     * @param degrees
     */
    public zoomOut(degrees: number): void {
        this.viewer.setHfov(this.viewer.getHfov() + degrees);
    }

    /**
     * zoom-out
     */
    public fullScreen(): void {
        this.viewer.toggleFullscreen();
    }
}

export { ViewerImpl as Viewer };
