import React, { useState, useEffect, useRef } from 'react';
import { Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { Trans } from '@lingui/react';
import Modal from '../../utils/modal/modal';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import ArchivedTours from '../tour/archived-tours';
import ToursList from './tours-list';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import {
  archiveTour,
  deleteArchivedTour,
  deleteTour,
  duplicateTour,
  getArchivedTours,
  getDeletedTours,
  getTours,
  getUsersTours,
  moveTourDifferentAccountAdmin,
  shareTour,
} from '../../../api-helper/api-tours';
import normalViewActive from '../../../images/dashboard-icons/ic-normal-view-active.svg';
import normalViewInactive from '../../../images/dashboard-icons/ic-normal-view-inactive.svg';
import gridViewActive from '../../../images/dashboard-icons/ic-grid-view-active.svg';
import gridViewInactive from '../../../images/dashboard-icons/ic-grid-view-inactive.svg';
import Footer from '../../utils/footer/footer';
import SearchBar from '../../utils/searchbar/searchbar';
import { ReportPageView, ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../../google-analytics-track-list';
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import './dashboard.scss';
import CustomSelect from '../../utils/custom-select/custom-select';
import { tooltipsDashboard } from '../../utils/tooltips/tooltips-data';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { connect, connectToUserNoPassword } from '../../../api-helper/api-user';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import { ITours } from '../../../api-helper/interface/interfaces';
import { EnumDollhouseStatus, IModalContent } from '../../utils/tour-views/interface';
import { downloadAllPanorama, validateForDollhouse } from '../../../api-helper/api-panoramas';
import { defineMessage } from '@lingui/macro';
import { linguiTranslateToolTip, linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { getDollhouseTours, manageDollhouseStatus, orderDollhouse, reassignDollhouse } from '../../../api-helper/api-dollhouse';

export interface IModules {
  [name: string]: boolean;
}

export enum EnumTabsState {
  LiveTours = 'liveTours',
  ArchivedTours = 'archivedTours',
  UsersLiveTours = 'usersLiveTours',
  DollhouseTours = 'dollhouseTours',
}

export interface IPagination {
  selected: number;
}

export interface IChangeSortPagination {
  sortBy?: string;
  page?: number;
  keyword?: string;
}

interface IPreviousSearchHistory {
  displayArchive?: boolean;
  displayDeleted?: boolean;
  orderOption: string;
  orderOptionName: string;
  pagination: number;
  tourIndex: number;
  wordSearch: string;
  dashboardView: string;
}

const Dashboard: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const { state } = useLocation<IPreviousSearchHistory>();
  const { handleLogoutState } = useAuth();
  const tourRef = useRef<HTMLDivElement>(null);
  const [liveTourTab, setLiveToursTab] = useState<EnumTabsState>();
  const [showUsersLiveToursTab, setShowUsersLiveToursTab] = useState(false);
  const [showDollhouseTab, setShowDollhouseTab] = useState(false);
  const [showOrderDollhouse, setShowOrderDollhouse] = useState(false);
  const [normalView, setNormalView] = useState<boolean>(true);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IModalContent>({ id: '' });
  const [tours, setTours] = useState<ITours[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [apiCallLoaded, setApiCallLoaded] = useState<boolean>(false);
  const [usersToursLoaded, setUsersToursLoaded] = useState<boolean>(true);
  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [error, setError] = useState<boolean>(false);
  // Users tours
  const [userTours, setUserTours] = useState<ITours[]>();
  const [userToursPageCount, setUserToursPageCount] = useState(0);
  const [usersTourTotalCount, setUsersTourTotalCount] = useState(0);
  const [usersToursPage, setUsersToursPage] = useState(0);
  const [keyword, setKeyword] = useState<string>('');
  // Dollhouse
  const [dollhouseTours, setDollhouseTours] = useState<any>();
  const [orderOptionDollhouse, setOrderOptionDollhouse] = useState('completed');
  const [orderOptionNameDollhouse, setOrderOptionNameDollhouse] = useState(linguiTranslateToString('Completed'));
  const [dollhouseToursPageCount, setDollhouseToursPageCount] = useState(0);
  const [dollhouseTourTotalCount, setDollhouseTourTotalCount] = useState(0);
  const [dollhousePage, setDollhousePage] = useState<number>(0);
  const [dollhouseKeyword, setDollhouseKeyword] = useState<string>('');
  const [dollhouseToursLoaded, setDollhouseToursLoaded] = useState<boolean>(false);
  const [errorDollhouseTours, setErrorDollhouseTours] = useState<boolean>(false);
  // Notification
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState('');
  const [orderOption, setOrderOption] = useState('newestOldest');
  const [orderOptionName, setOrderOptionName] = useState(linguiTranslateToString('Newest to Oldest'));
  const dashboardRef = useRef<HTMLDivElement>(null);
  const [archivedTours, setArchivedTours] = useState<ITours[]>();
  const [deletedTours, setDeletedTours] = useState<ITours[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showCustomDropDown, setShowCustomDropDown] = useState<boolean>(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState<boolean>(false);
  const [currentTooltipStep, setCurrentTooltipStep] = useState<number>(0);
  const [showTabs, setShowTabs] = useState<boolean>(false);
  const [master, setMaster] = useState(false);
  const [currency, setCurrency] = useState<string>('gbp');
  const [notificationDuration, setNotificationDuration] = useState<number>(2000);
  const config = useConfig();
  const steps = linguiTranslateToolTip(tooltipsDashboard);
  const params = new URLSearchParams(window.location.search);
  const [orderDollhouseButtonEnabled, setOrderDollhouseButtonEnabled] = useState(true);

  useEffect(() => {
    if (config) {
      const getUsersToursConfig = config.modules.find((a: IModules) => Object.keys(a)[0] === 'usersTourTab')?.usersTourTab;
      const getDollhouseToursConfig = config.modules.find((a: IModules) => Object.keys(a)[0] === 'dollhouseTab')?.dollhouseTab;
      const getDollhouseOrderConfig = config.modules.find(
        (a: IModules) => Object.keys(a)[0] === 'dollhouseOrderOption',
      )?.dollhouseOrderOption;
      const masterTab = config.modules.find((a: IModules) => Object.keys(a)[0] === 'masterTab')?.masterTab;
      setMaster(masterTab);
      if (getUsersToursConfig) {
        setShowUsersLiveToursTab(getUsersToursConfig);
      }
      if (getDollhouseToursConfig) {
        setShowDollhouseTab(getDollhouseToursConfig);
      }
      if (getDollhouseOrderConfig) {
        setShowOrderDollhouse(getDollhouseOrderConfig);
      }
      setShowTabs(true);
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  const onExit = () => {
    setIsTooltipClicked(false);
  };

  const onStepsCompleted = () => {
    setCurrentTooltipStep(0);
  };

  const stepsInfo = (currentStep: number) => {
    setCurrentTooltipStep(currentStep);
  };

  const activeTooltipHandler = () => {
    //Report Custom Category and Event
    ReportCustomEvent(EnumCategory.Tooltip, EnumEvent.TooltipDashboard);
    tourRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => setIsTooltipClicked(!isTooltipClicked), 700);
  };

  const toggleCustomDropdown = (option?: boolean) => {
    if (option) {
      setShowCustomDropDown(option);
    } else {
      setShowCustomDropDown(!showCustomDropDown);
    }
  };

  // Search bar logic
  const [itHasTours, setItHasTours] = useState(false);
  // tours ref
  const toursRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (tours && tours.length) {
      setItHasTours(true);
    } else if (tours && tours.length === 0 && keyword === '') {
      // new account work
      setItHasTours(false);
    }
  }, [tours]);

  useEffect(() => {
    if (dollhouseTours && dollhouseTours.length) {
      setItHasTours(true);
    } else if (dollhouseTours && dollhouseTours.length === 0 && dollhouseKeyword === '') {
      // new account work
      setItHasTours(false);
    }
  }, [dollhouseTours]);

  useEffect(() => {
    if (userTours && userTours.length) {
      setItHasTours(true);
    } else if (userTours && userTours.length === 0 && keyword === '') {
      // new account work
      setItHasTours(false);
    }
  }, [userTours]);

  useEffect(() => {
    const urlPath = window.location.pathname;
    onChangeUrl(urlPath);
  }, []);

  useEffect(() => {
    // Changes tab ONLY when back button is pressed
    window.onpopstate = (e: any) => {
      onChangeUrl(e.currentTarget.location.pathname);
      onChangeUrlPageNumberSortBy(e.currentTarget.location.search, e.currentTarget.location.pathname);
    };
  }, []);

  useEffect(() => {
    managePaginationSortBy();
  }, []);

  const managePaginationSortBy = () => {
    const pageFromUrl = Number(params.get('page'));
    const sortByUrl = params.get('sortBy');
    const obj: IChangeSortPagination = {};

    if (sortByUrl) {
      if (window.location.pathname.includes('live-tours') || window.location.pathname.includes('users-tours')) {
        const [value, name] = onChangeSortBy(sortByUrl);
        setOrderOption(value);
        setOrderOptionName(name);
        obj.sortBy = value;
      } else if (window.location.pathname.includes('dollhouse-tours')) {
        const [value, name] = onChangeSortByDollhouse(sortByUrl);
        setOrderOptionDollhouse(value);
        setOrderOptionNameDollhouse(name);
        obj.sortBy = value;
      }
    }
    if (pageFromUrl) {
      obj.page = pageFromUrl - 1 || 0;
    }
    if (window.location.pathname.includes('live-tours')) {
      getToursAsync(obj);
    } else if (window.location.pathname.includes('users-tours')) {
      getUsersToursAsync(obj);
    } else if (window.location.pathname.includes('dollhouse-tours')) {
      getDollhouseToursAsync(obj);
    }
  };

  const changeSortBy = (option: IChangeSortPagination, returnValue?: boolean) => {
    const obj: IChangeSortPagination = {};
    if (option.sortBy) {
      const [value, name] = onChangeSortBy(option.sortBy);
      if (
        liveTourTab === EnumTabsState.LiveTours ||
        liveTourTab === EnumTabsState.UsersLiveTours ||
        window.location.pathname.includes('live-tours') ||
        window.location.pathname.includes('users-tours')
      ) {
        setOrderOption(value);
        setOrderOptionName(name);
      }
      if (returnValue) {
        return value;
      }
      obj.sortBy = value;
    }
    if (!returnValue) {
      if (liveTourTab === EnumTabsState.LiveTours || window.location.pathname.includes('live-tours')) {
        getToursAsync(obj);
      } else if (liveTourTab === EnumTabsState.UsersLiveTours || window.location.pathname.includes('users-tours')) {
        getUsersToursAsync(obj);
      }
    }
  };

  const onChangeUrlPageNumberSortBy = (search: string, location: string) => {
    const params = search.slice(1).split('&');
    const page = params.find(element => element.includes('page'))?.split('=')[1];
    const previousPage = Number(page) || 1;
    const sort = params.find(element => element.includes('sortBy'))?.split('=')[1];
    const previousSort = sort?.split('%20').join(' ');

    if (location.includes('live-tours')) {
      setPage(previousPage - 1);
      const value = changeSortBy({ sortBy: previousSort || 'Newest - Oldest' }, true);
      getToursAsync({ page: previousPage - 1, sortBy: value });
    }
    if (location.includes('users-tours')) {
      setUsersToursPage(previousPage - 1);
      const value = changeSortBy({ sortBy: previousSort || 'Newest - Oldest' }, true);
      getUsersToursAsync({ page: previousPage - 1, sortBy: value });
    }
    if (location.includes('dollhouse-tours')) {
      setDollhousePage(previousPage - 1);
      const value = changeSortByDollhouse(previousSort || 'Newest - Oldest', true);
      getDollhouseToursAsync({ page: previousPage - 1, sortBy: value });
    }
  };

  const changeSortByDollhouse = (option: string, returnValue?: boolean) => {
    const [value, name] = onChangeSortByDollhouse(option);
    if (liveTourTab === EnumTabsState.DollhouseTours || window.location.pathname.includes('dollhouse-tours')) {
      setOrderOptionDollhouse(value);
      setOrderOptionNameDollhouse(name);
    }
    if (returnValue) {
      return value;
    } else {
      getDollhouseToursAsync({ sortBy: value });
    }
  };

  const onChangeUrl = (urlPath: string) => {
    if (urlPath.includes('live-tours') || urlPath === '/') {
      setLiveToursTab(EnumTabsState.LiveTours);
    } else if (urlPath.includes('users-tours')) {
      setLiveToursTab(EnumTabsState.UsersLiveTours);
    } else if (urlPath.includes('dollhouse-tours')) {
      setLiveToursTab(EnumTabsState.DollhouseTours);
    } else if (urlPath.includes('archived-tours')) {
      setLiveToursTab(EnumTabsState.ArchivedTours);
    }
  };

  useEffect(() => {
    //Google report page
    ReportPageView(EnumPagesTitles.Dashboard);
  }, []);

  // Return to previous pagination page and searched word
  useEffect(() => {
    if (
      loaded &&
      state &&
      (state.pagination || state.tourIndex || state.wordSearch || state.orderOptionName || state.orderOption || state.dashboardView)
    ) {
      setPage(state.pagination);
      if (toursRef && toursRef.current && toursRef.current.length > 0) {
        toursRef?.current[state.tourIndex].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
      setKeyword(state.wordSearch);
      if (state.orderOptionName && state.orderOption) {
        setOrderOptionName(state.orderOptionName);
        setOrderOption(state.orderOption);
      }

      if (state?.dashboardView === 'rows') {
        changeViews('normal');
      } else {
        changeViews();
      }

      getToursAsync();
      history.replace('', null);
    }
  }, [loaded, toursRef]);

  useEffect(() => {
    setApiCallLoaded(false);
    if (liveTourTab === EnumTabsState.LiveTours) {
      if (!window.location.pathname.includes('live-tours')) {
        history.push('/live-tours');
        changeSortBy({ sortBy: 'Newest - Oldest' });
        setPage(0);
      }
    }
  }, [liveTourTab]);

  useEffect(() => {
    setApiCallLoaded(false);

    if (liveTourTab === EnumTabsState.UsersLiveTours) {
      const params = new URLSearchParams(window.location.search);
      const imageEnv = params.get('imageEnv');
      if (!window.location.pathname.includes('users-tours')) {
        history.push('/users-tours', `${imageEnv ? '?imageEnv=' + imageEnv : ''}`);
        changeSortBy({ sortBy: 'Newest - Oldest' });
        setUsersToursPage(0);
      }
      setNormalView(true);
    }
  }, [liveTourTab]);

  useEffect(() => {
    setApiCallLoaded(false);
    if (liveTourTab === EnumTabsState.DollhouseTours) {
      const params = new URLSearchParams(window.location.search);
      const imageEnv = params.get('imageEnv');
      if (!window.location.pathname.includes('dollhouse-tours')) {
        history.push('/dollhouse-tours', `${imageEnv ? '?imageEnv=' + imageEnv : ''}`);
        changeSortByDollhouse('Newest - Oldest');
        setDollhousePage(0);
      }
      setNormalView(true);
    }
  }, [liveTourTab]);

  useEffect(() => {
    if (liveTourTab === EnumTabsState.ArchivedTours) {
      const params = new URLSearchParams(window.location.search);
      const imageEnv = params.get('imageEnv');
      if (!window.location.pathname.includes('archived-tours')) {
        history.push('/archived-tours', `${imageEnv ? '?imageEnv=' + imageEnv : ''}`);
      }
      getToursArchiveAsync();
    }
  }, [liveTourTab]);

  useEffect(() => {
    if (state?.displayArchive) {
      const message = String(<Trans id='Tour successfully archived' />);
      toggleNotification(EnumNotificationType.Success, message);
      history.replace('', null);
    } else if (state?.displayDeleted) {
      const message = String(<Trans id='Tour successfully deleted' />);
      toggleNotification(EnumNotificationType.Success, message);
      history.replace('', null);
    }
  }, []);

  const handlerSetSearchBarValue = (keywordValue: string) => {
    if (liveTourTab === EnumTabsState.DollhouseTours) {
      setDollhouseKeyword(keywordValue);
    } else {
      setKeyword(keywordValue);
    }
  };

  const handlerSearching = (isSearchbarLoaded: boolean, page: number) => {
    if (liveTourTab === EnumTabsState.LiveTours) {
      ReportCustomEvent(EnumCategory.Header, EnumEvent.SearchForTour);
      setLoaded(isSearchbarLoaded);
      setPage(page);
      getToursAsync();
    } else if (liveTourTab === EnumTabsState.DollhouseTours) {
      setDollhouseToursLoaded(isSearchbarLoaded);
      setDollhousePage(page);
      getDollhouseToursAsync();
    } else {
      ReportCustomEvent(EnumCategory.Header, EnumEvent.SearchForTour);
      setLoaded(isSearchbarLoaded);
      setUsersToursPage(page);
      getUsersToursAsync();
    }
  };

  const changeDollhouseStatus = async (tourId: string, status: EnumDollhouseStatus) => {
    setApiCallLoaded(false);
    try {
      await manageDollhouseStatus(tourId, status);
      const message = 'Dollhouse status successfully changed';
      toggleNotification(EnumNotificationType.Success, message);
      getDollhouseToursAsync();
      setApiCallLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      const message = err?.message;
      toggleNotification(EnumNotificationType.Error, message);
      setApiCallLoaded(true);
    }
  };

  const reassignDollhouseAsync = async (tourId: string, email: string, status?: EnumDollhouseStatus) => {
    try {
      await reassignDollhouse(tourId, email);
      if (status !== undefined) {
        await manageDollhouseStatus(tourId, status);
      }

      const message = 'Dollhouse successfully assigned';
      toggleNotification(EnumNotificationType.Success, message);
      getDollhouseToursAsync();
      toggleModalHide();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      const message = err?.message;
      toggleNotification(EnumNotificationType.Error, message);
    }
  };

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    if (type === EnumNotificationType.Error) {
      setError(!error);
      setErrorDollhouseTours(true);
    }
    setApiCallLoaded(true);
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const getToursAsync = async (previousValues?: IChangeSortPagination) => {
    try {
      const sort = previousValues?.sortBy !== undefined ? previousValues.sortBy : orderOption;
      const searchWord = previousValues?.keyword ? previousValues?.keyword : keyword;
      const pagination = previousValues?.page !== undefined ? previousValues?.page : page;
      const dataResponse = await getTours(pagination, searchWord, sort);
      setPageCount(Math.ceil(dataResponse.headers['x-pagination-count']));
      setTotalCount(dataResponse.headers['x-total-count']);
      setTours(dataResponse.data);
      setLoaded(true);
      setApiCallLoaded(true);
      if (previousValues?.page !== undefined) {
        setPage(previousValues.page);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const getDollhouseToursAsync = async (previousValues?: IChangeSortPagination) => {
    try {
      const sort = previousValues?.sortBy !== undefined ? previousValues.sortBy : orderOptionDollhouse;
      const searchWord = previousValues?.keyword ? previousValues?.keyword : dollhouseKeyword;
      const pagination = previousValues?.page !== undefined ? previousValues?.page : dollhousePage;
      const dataResponse = await getDollhouseTours(pagination, searchWord, sort);
      setDollhouseToursPageCount(Math.ceil(dataResponse.headers['x-pagination-count']));
      setDollhouseTourTotalCount(dataResponse.headers['x-total-count']);
      setDollhouseTours(dataResponse.data);
      setDollhouseToursLoaded(true);
      setApiCallLoaded(true);
      if (previousValues?.page !== undefined) {
        setDollhousePage(previousValues.page);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const getUsersToursAsync = async (previousValues?: IChangeSortPagination) => {
    try {
      const sort = previousValues?.sortBy !== undefined ? previousValues.sortBy : orderOption;
      const searchWord = previousValues?.keyword ? previousValues?.keyword : keyword;
      const pagination = previousValues?.page !== undefined ? previousValues?.page : usersToursPage;
      const dataResponse = await getUsersTours(pagination, searchWord, sort);
      setUserToursPageCount(Math.ceil(dataResponse.headers['x-pagination-count']));
      setUsersTourTotalCount(dataResponse.headers['x-total-count']);
      setUserTours(dataResponse.data);
      setUsersToursLoaded(true);
      setLoaded(true);
      setApiCallLoaded(true);

      if (previousValues?.page !== undefined) {
        setUsersToursPage(previousValues.page);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setError(true);
      setUsersToursLoaded(true);
      setLoaded(true);
      setApiCallLoaded(true);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const getToursArchiveAsync = async () => {
    try {
      const archivedResponseTours = await getArchivedTours();
      setArchivedTours(archivedResponseTours);
      const deletedResponseTours = await getDeletedTours();
      setDeletedTours(deletedResponseTours);
      setApiCallLoaded(true);
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
      setApiCallLoaded(true);
    }
  };

  const changeViews = (view?: string): void => {
    if (view) {
      setNormalView(true);
    } else {
      setNormalView(false);
    }
  };

  const toggleModalShow = (modalContent: IModalContent) => {
    setModalContent(modalContent);
    setDisplayModal(true);
  };

  const toggleModalHide = (): void => {
    setDisplayModal(false);
  };

  const scrollToTop = () => {
    dashboardRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const archiveTours = async (id: string, archive: boolean) => {
    scrollToTop();
    let errorAction;
    let successAction;
    if (archive) {
      errorAction = linguiTranslateToString(' archiving');
      successAction = linguiTranslateToString(' archived');
    } else {
      errorAction = linguiTranslateToString(' unarchiving');
      successAction = linguiTranslateToString(' unarchived');
    }
    try {
      await archiveTour(id, archive);
      if (liveTourTab === EnumTabsState.LiveTours) {
        getToursAsync();
      } else if (liveTourTab === EnumTabsState.ArchivedTours) {
        getToursArchiveAsync();
      }
      const message = linguiTranslateToString('Tour successfully') + successAction;
      toggleNotification(EnumNotificationType.Success, message);
    } catch (error) {
      const message = linguiTranslateToString('There was an error ') + errorAction + linguiTranslateToString(' your tour');
      toggleNotification(EnumNotificationType.Error, message);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const duplicateATour = async (tourId: string) => {
    scrollToTop();
    setApiCallLoaded(false);
    try {
      await duplicateTour(tourId);
      const message = linguiTranslateToString('Tour successfully duplicated');
      toggleNotification(EnumNotificationType.Success, message);
      setApiCallLoaded(true);
      // Update the tours list
      getToursAsync();
    } catch (error) {
      const message = linguiTranslateToString('There was an error duplicating the tour');
      toggleNotification(EnumNotificationType.Error, message);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const deleteTourMatch = async () => {
    try {
      scrollToTop();
      await deleteTour(modalContent?.tourId || '');
      // Update the tours list
      if (liveTourTab === EnumTabsState.LiveTours) {
        getToursAsync();
      } else if (liveTourTab === EnumTabsState.ArchivedTours) {
        getToursArchiveAsync();
      }
      const message = linguiTranslateToString('Tour successfully deleted');
      toggleNotification(EnumNotificationType.Success, message);
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
    toggleModalHide();
  };

  const deleteArchived = async (tourId: string) => {
    scrollToTop();
    try {
      scrollToTop();
      await deleteArchivedTour(tourId);
      // Update the tours list
      if (liveTourTab === EnumTabsState.LiveTours) {
        getToursAsync();
      } else if (liveTourTab === EnumTabsState.ArchivedTours) {
        getToursArchiveAsync();
      }
      const message = linguiTranslateToString('Tour successfully deleted');
      toggleNotification(EnumNotificationType.Success, message);
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
    toggleModalHide();
  };

  const shareTourLink = async (tourId: string, recipient: string[]) => {
    try {
      await shareTour(tourId, recipient);
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const handlePaginationSearch = (pageSelected: IPagination) => {
    if (liveTourTab === EnumTabsState.LiveTours) {
      setPage(pageSelected.selected);
      getToursAsync({ page: pageSelected.selected });
    } else if (liveTourTab === EnumTabsState.UsersLiveTours) {
      setUsersToursPage(pageSelected.selected);
      getUsersToursAsync({ page: pageSelected.selected });
    }
  };

  const handlePaginationSearchDollhouse = (pageSelected: IPagination) => {
    setLoaded(false);
    setDollhousePage(pageSelected.selected);
    getDollhouseToursAsync({ page: pageSelected.selected });
  };

  const onChangeSortByDollhouse = (option: string) => {
    if (option === 'Completed') {
      return ['completed', linguiTranslateToString('Completed')];
    } else if (option === 'Pending') {
      return ['pending', linguiTranslateToString('Pending')];
    } else if (option === 'To do') {
      return ['toDo', linguiTranslateToString('To do')];
    } else if (option === 'Awaiting Validation') {
      return ['waitingValidation', linguiTranslateToString('Awaiting Validation')];
    } else {
      return ['completed', linguiTranslateToString('Completed')];
    }
  };

  const onChangeSortBy = (option: string): string[] => {
    if (option === 'Alphabetical order') {
      return ['alphabetical', linguiTranslateToString('Alphabetical order')];
    } else if (option === 'Last updated') {
      return ['lastUpdated', linguiTranslateToString('Last updated')];
    } else if (option === 'Newest to oldest') {
      return ['newestOldest', linguiTranslateToString('Newest to oldest')];
    } else if (option === 'Oldest to newest') {
      return ['oldestNewest', linguiTranslateToString('Oldest to newest')];
    } else {
      return ['newestOldest', linguiTranslateToString('Newest - Oldest')];
    }
  };

  const connectToUserGoToStudio = async (userId: string, tourId: string) => {
    try {
      const userConnect = await connectToUserNoPassword(userId);
      if (!userConnect.data.token) {
        // Show error notification
      }
      await connect(userConnect.data.token, 'dashboard');
      document.location.href = './editing-viewer/?tourId=' + tourId;
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const removeAnySpecialCharacters = (fileName: string) => {
    // eslint-disable-next-line
    return fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<> \{\}\[\]\\\/]/gi, '');
  };

  const downloadAllPanoramas = async (tourId: string, tourName: string) => {
    setApiCallLoaded(false);
    try {
      const responseData = await downloadAllPanorama(tourId);
      // Get file name
      const filename = removeAnySpecialCharacters(tourName) + '.zip';
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setApiCallLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setApiCallLoaded(true);
    }
  };

  const moveTourToNewAccount = async (newEmail: string, tourId: string) => {
    const message = linguiTranslateToString('We are transferring your tour to the new account');
    toggleNotification(EnumNotificationType.Info, message);
    try {
      setDisplayModal(false);
      await moveTourDifferentAccountAdmin(tourId, newEmail);
      const messageSuccess = linguiTranslateToString('The tour has been successfully transferred');
      toggleNotification(EnumNotificationType.Success, messageSuccess);
      getToursAsync();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      const errorMessage = linguiTranslateToString('There is an issue with the account you are trying to transfer to');
      toggleNotification(EnumNotificationType.Error, errorMessage);
      setDisplayModal(false);
    }
  };

  const orderDollhouseTourId = async (tourId: string, backUrl: string, panoramaCount: number) => {
    setOrderDollhouseButtonEnabled(false);
    setNotificationDuration(10000);
    try {
      const validatePanoramas = await validateForDollhouse(tourId);
      const missingLinks = validatePanoramas.missingLinks;
      const pendingPanoramas = validatePanoramas.pendingPanoramas;
      if (panoramaCount > 1 && (missingLinks.length > 0 || pendingPanoramas.length > 0)) {
        let missingLinksMessage = '';
        let pendingLinksMessage = '';
        if (missingLinks.length > 0) {
          const listPanoramas = missingLinks.join(', ');
          const plural = missingLinks.length > 1 ? true : false;
          missingLinksMessage = `The link${plural ? 's' : ''} in ${listPanoramas} ${missingLinks.length > 1 ? 'are' : 'is'} missing`;
        }
        if (pendingPanoramas.length > 0) {
          const listPanoramas = pendingPanoramas.join(', ');
          const plural = pendingPanoramas.length > 1 ? true : false;
          pendingLinksMessage = `${listPanoramas} ${plural ? 'have' : 'has'} not been uploaded yet`;
        }
        const plural = missingLinksMessage.length > 0 || pendingLinksMessage.length > 0 ? true : false;
        const message = missingLinksMessage + pendingLinksMessage + `. Please fix ${plural ? 'these' : 'this'} before ordering a dollhouse`;
        toggleNotification(EnumNotificationType.Error, linguiTranslateToString(message));
      } else {
        const orderingDollhouse = await orderDollhouse(tourId, backUrl, currency);
        if (orderingDollhouse?.redirectUrl) {
          // redirect to payment checkout
          window.location.href = orderingDollhouse.redirectUrl;
        } else {
          toggleModalHide();
          if (liveTourTab === EnumTabsState.UsersLiveTours) {
            getUsersToursAsync();
          } else {
            getToursAsync();
          }
          const messageSuccess = linguiTranslateToString('The dollhouse has been successfully ordered');
          toggleNotification(EnumNotificationType.Success, messageSuccess);
        }
      }
    } catch (error) {
      const err = error as any;
      handleError(err, handleLogoutState);
      const message = err?.message || err?.error.data.error;
      toggleNotification(
        EnumNotificationType.Error,
        message || linguiTranslateToString('There is an issue ordering a dollhouse for this tour'),
      );
      setDisplayModal(false);
    } finally {
      setNotificationDuration(2000);
      setOrderDollhouseButtonEnabled(true);
    }
  };

  const changePaginationPage = (page: IPagination) => {
    const sortByUrl = params.get('sortBy');
    if (liveTourTab === EnumTabsState.LiveTours || liveTourTab === EnumTabsState.UsersLiveTours) {
      history.push(
        `/${liveTourTab === EnumTabsState.LiveTours ? 'live-tours' : 'users-tours'}?${
          sortByUrl ? `sortBy=${orderOptionName}&page=${page.selected + 1}` : `page=${page.selected + 1}`
        }`,
      );
      handlePaginationSearch(page);
    } else if (liveTourTab === EnumTabsState.DollhouseTours) {
      history.push(
        `/dollhouse-tours?${sortByUrl ? `sortBy=${orderOptionNameDollhouse}&page=${page.selected + 1}` : `page=${page.selected + 1}`}`,
      );

      handlePaginationSearchDollhouse(page);
    }
  };

  const changeSortByUrl = (sortBy: string) => {
    const pageFromUrl = Number(params.get('page'));
    if (liveTourTab === EnumTabsState.LiveTours) {
      history.push(`/live-tours?${pageFromUrl ? `sortBy=${sortBy}&page=${page + 1}` : `sortBy=${sortBy}`}`);
      changeSortBy({ sortBy });
    }
    if (liveTourTab === EnumTabsState.UsersLiveTours) {
      history.push(`/users-tours?${pageFromUrl ? `sortBy=${sortBy}&page=${usersToursPage + 1}` : `sortBy=${sortBy}`}`);
      changeSortBy({ sortBy });
    } else if (liveTourTab === EnumTabsState.DollhouseTours) {
      history.push(`/dollhouse-tours?${pageFromUrl ? `sortBy=${sortBy}&page=${dollhousePage + 1}` : `sortBy=${sortBy}`}`);
      changeSortByDollhouse(sortBy);
    }
  };

  return (
    <div className='dashboard-layout'>
      <NavSidebar isTooltipActive={true} activeTooltipHandler={activeTooltipHandler} isToolTipClicked={isTooltipClicked} />

      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
          fullScreen={false}
          duration={notificationDuration}
        />
      )}
      <Steps
        enabled={isTooltipClicked}
        steps={steps}
        initialStep={currentTooltipStep}
        onAfterChange={stepsInfo}
        onComplete={onStepsCompleted}
        onExit={onExit}
        options={{ hideNext: false, exitOnOverlayClick: false, showStepNumbers: false, showBullets: false, disableInteraction: true }}
      />
      <LoadingSpinner loaded={apiCallLoaded} />
      <LoadingSpinner loaded={usersToursLoaded} />
      <div ref={dashboardRef}></div>
      <div ref={tourRef} className='dashboard'>
        <div className='content-wrapper'>
          {displayModal && (
            <Modal
              liveTourTab={liveTourTab}
              orderDollhouseButtonEnabled={orderDollhouseButtonEnabled}
              hideModal={toggleModalHide}
              modalContent={modalContent}
              deleteTourMatch={deleteTourMatch}
              shareTourLink={shareTourLink}
              getToursAsync={getToursAsync}
              getUsersToursAsync={getUsersToursAsync}
              moveTourToNewAccount={moveTourToNewAccount}
              toggleNotification={toggleNotification}
              orderDollhouseTourId={orderDollhouseTourId}
              reassignDollhouse={reassignDollhouseAsync}
            />
          )}
          <ul className='dashboard-header' style={{ opacity: showTabs ? 1 : 0 }}>
            <div className='dashboard-main-links'>
              <button
                id='your-live-tour'
                className={`btn-dashboard ${liveTourTab === EnumTabsState.LiveTours ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}`}
                onClick={() => setLiveToursTab(EnumTabsState.LiveTours)}
              >
                <Trans id='Your Live Tours' render={({ translation }) => <h3 className='btn-dashboard-text'>{translation}</h3>} />
              </button>
              {showUsersLiveToursTab && (
                <>
                  <button
                    id='users-tours'
                    className={`btn-dashboard ${
                      liveTourTab === EnumTabsState.UsersLiveTours ? 'btn-dashboard-active' : 'btn-dashboard-inactive'
                    }`}
                    onClick={() => {
                      ReportCustomEvent(EnumCategory.Header, EnumEvent.UsersLiveTours);
                      setLiveToursTab(EnumTabsState.UsersLiveTours);
                    }}
                  >
                    <Trans id='Users Live Tours' render={({ translation }) => <h3 className='btn-dashboard-text'>{translation}</h3>} />
                  </button>
                </>
              )}
              {showDollhouseTab && (
                <button
                  id='dollhouse-tours'
                  className={`btn-dashboard ${
                    liveTourTab === EnumTabsState.DollhouseTours ? 'btn-dashboard-active' : 'btn-dashboard-inactive'
                  }`}
                  onClick={() => setLiveToursTab(EnumTabsState.DollhouseTours)}
                >
                  <Trans id='Dollhouses' render={({ translation }) => <h3 className='btn-dashboard-text'>{translation}</h3>} />
                </button>
              )}
              <button
                id='archived-tour'
                className={`btn-dashboard ${
                  liveTourTab === EnumTabsState.ArchivedTours ? 'btn-dashboard-active' : 'btn-dashboard-inactive'
                }`}
                onClick={() => {
                  ReportCustomEvent(EnumCategory.Header, EnumEvent.ArchiveTour);
                  setLiveToursTab(EnumTabsState.ArchivedTours);
                }}
              >
                <Trans id='Archived Tours' render={({ translation }) => <h3 className='btn-dashboard-text'>{translation}</h3>} />
              </button>
            </div>

            <Link to='/create-tour'>
              <button
                id='create-tour'
                className='btn-create-tour button-hover'
                onClick={() => {
                  //Report Custom Category and Event
                  ReportCustomEvent(EnumCategory.Header, EnumEvent.CreateATour);
                }}
              >
                <Trans id='Create a tour' render={({ translation }) => <div className='btn-dashboard-text'>{translation}</div>} />
              </button>
            </Link>
          </ul>
          {(liveTourTab === EnumTabsState.LiveTours ||
            liveTourTab === EnumTabsState.UsersLiveTours ||
            liveTourTab === EnumTabsState.DollhouseTours) &&
            itHasTours && (
              <div className='dashboard-search-and-filter'>
                {liveTourTab === EnumTabsState.DollhouseTours ? (
                  <div id='searchbar'>
                    <Trans
                      id={'Search for a virtual tour by street name, postcode, city'}
                      render={({ translation }) => (
                        <SearchBar
                          placeholder={String(translation)}
                          searchBarValue={dollhouseKeyword}
                          handlerSetSearchBarValue={handlerSetSearchBarValue}
                          handlerSearching={handlerSearching}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div id='searchbar'>
                    <Trans
                      id={'Search for a virtual tour by street name, postcode, city'}
                      render={({ translation }) => (
                        <SearchBar
                          placeholder={String(translation)}
                          searchBarValue={keyword}
                          handlerSetSearchBarValue={handlerSetSearchBarValue}
                          handlerSearching={handlerSearching}
                        />
                      )}
                    />
                  </div>
                )}

                <div className={`${liveTourTab === EnumTabsState.LiveTours ? 'sort-and-filter-container' : 'only-sort-container'}`}>
                  <div className='sort-by-container'>
                    <label className='sort-by-label'>
                      <Trans id='Sort by' />
                    </label>
                    {liveTourTab === EnumTabsState.DollhouseTours ? (
                      <CustomSelect
                        value={orderOptionNameDollhouse}
                        options={[
                          defineMessage({ message: 'Completed' }),
                          defineMessage({ message: 'Pending' }),
                          defineMessage({ message: 'To do' }),
                          defineMessage({ message: 'Awaiting Validation' }),
                        ]}
                        changeDropdownValue={changeSortByUrl}
                        showCustomDropDown={showCustomDropDown}
                        setShowCustomDropDown={toggleCustomDropdown}
                        customZIndex={1}
                        customClass={`am-custom-select ${showCustomDropDown ? 'am-custom-select-focus' : ''}`}
                        customArrow='am-custom-arrow'
                      />
                    ) : (
                      <CustomSelect
                        value={orderOptionName}
                        options={[
                          defineMessage({ message: 'Alphabetical order' }),
                          defineMessage({ message: 'Last updated' }),
                          defineMessage({ message: 'Newest to oldest' }),
                          defineMessage({ message: 'Oldest to newest' }),
                        ]}
                        changeDropdownValue={changeSortByUrl}
                        showCustomDropDown={showCustomDropDown}
                        setShowCustomDropDown={toggleCustomDropdown}
                        customZIndex={1}
                        customClass={`am-custom-select ${showCustomDropDown ? 'am-custom-select-focus' : ''}`}
                        customArrow='am-custom-arrow'
                      />
                    )}
                  </div>
                  <div className='filter-container'>
                    {liveTourTab === EnumTabsState.LiveTours && (
                      <button
                        id='normal-view'
                        className='filter-btn-view'
                        onClick={() => {
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.ChangeToListView);
                          changeViews('normal');
                        }}
                      >
                        <img src={normalView ? normalViewActive : normalViewInactive} alt='List view' />
                      </button>
                    )}
                    {liveTourTab === EnumTabsState.LiveTours && (
                      <button
                        id='grid-view'
                        className='filter-btn-view'
                        onClick={() => {
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.ChangeToGridView);
                          changeViews();
                        }}
                      >
                        <img src={normalView ? gridViewInactive : gridViewActive} alt='Grid view' />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          {liveTourTab === EnumTabsState.LiveTours ? (
            <React.Fragment>
              {itHasTours && totalCount > 0 ? (
                <div className='dash-number-of-tours'>
                  <Trans
                    id='Live tours'
                    render={({ translation }) => (
                      <p>
                        <b>{totalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</b> {translation}
                      </p>
                    )}
                  />
                </div>
              ) : null}
              <div className='dashboard-display-tours-section'>
                <ToursList
                  keyword={keyword}
                  orderOptionName={orderOptionName}
                  orderOption={orderOption}
                  loaded={loaded}
                  error={error}
                  tours={tours}
                  toursRef={toursRef}
                  archiveTours={archiveTours}
                  duplicateATour={duplicateATour}
                  normalView={normalView}
                  showModal={toggleModalShow}
                  handlePaginationSearch={handlePaginationSearch}
                  pageCount={pageCount}
                  page={page}
                  itHasTours={itHasTours}
                  isTooltipActive={isTooltipClicked}
                  activeTooltipHandler={activeTooltipHandler}
                  downloadAllPanoramas={downloadAllPanoramas}
                  showDollhouseTab={showDollhouseTab}
                  showOrderDollhouse={showOrderDollhouse}
                  liveTourTab={liveTourTab}
                  changePaginationPage={changePaginationPage}
                />
              </div>
            </React.Fragment>
          ) : liveTourTab === EnumTabsState.ArchivedTours ? (
            <ArchivedTours
              toursRef={toursRef}
              toggleNotification={toggleNotification}
              archivedTours={archivedTours}
              deletedTours={deletedTours}
              scrollToTop={scrollToTop}
              deleteArchived={deleteArchived}
              showModal={toggleModalShow}
              getToursAsync={getToursAsync}
              getToursArchiveAsync={getToursArchiveAsync}
              archiveTours={archiveTours}
              liveTourTab={liveTourTab}
            />
          ) : liveTourTab === EnumTabsState.DollhouseTours ? (
            <React.Fragment>
              {dollhouseTourTotalCount > 0 ? (
                <div className='dash-number-of-tours'>
                  <Trans
                    id='Dollhouses'
                    render={({ translation }) => (
                      <p>
                        <b>{dollhouseTourTotalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</b> {translation}
                      </p>
                    )}
                  />
                </div>
              ) : null}
              <div className='dashboard-display-tours-section'>
                <ToursList
                  keyword={dollhouseKeyword}
                  orderOptionName={orderOptionNameDollhouse}
                  orderOption={orderOptionDollhouse}
                  loaded={dollhouseToursLoaded}
                  error={errorDollhouseTours}
                  tours={dollhouseTours}
                  toursRef={toursRef}
                  archiveTours={archiveTours}
                  duplicateATour={duplicateATour}
                  normalView={normalView}
                  showModal={toggleModalShow}
                  handlePaginationSearch={handlePaginationSearchDollhouse}
                  pageCount={dollhouseToursPageCount}
                  page={dollhousePage}
                  itHasTours={itHasTours}
                  connectToUserGoToStudio={connectToUserGoToStudio}
                  isTooltipActive={isTooltipClicked}
                  activeTooltipHandler={activeTooltipHandler}
                  downloadAllPanoramas={downloadAllPanoramas}
                  dollHouseFeatures={true}
                  master={master}
                  changeDollhouseStatus={changeDollhouseStatus}
                  liveTourTab={liveTourTab}
                  changePaginationPage={changePaginationPage}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {itHasTours && totalCount > 0 ? (
                <div className='dash-number-of-tours'>
                  <Trans
                    id='Live tours'
                    render={({ translation }) => (
                      <p>
                        <b>{usersTourTotalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</b> {translation}
                      </p>
                    )}
                  />
                </div>
              ) : null}
              <div className='dashboard-display-tours-section'>
                <ToursList
                  keyword={keyword}
                  orderOptionName={orderOptionName}
                  orderOption={orderOption}
                  loaded={loaded}
                  error={error}
                  tours={userTours}
                  toursRef={toursRef}
                  archiveTours={archiveTours}
                  duplicateATour={duplicateATour}
                  normalView={normalView}
                  showModal={toggleModalShow}
                  handlePaginationSearch={handlePaginationSearch}
                  pageCount={userToursPageCount}
                  page={usersToursPage}
                  itHasTours={itHasTours}
                  isTooltipActive={isTooltipClicked}
                  activeTooltipHandler={activeTooltipHandler}
                  rootFeatures={true}
                  connectToUserGoToStudio={connectToUserGoToStudio}
                  downloadAllPanoramas={downloadAllPanoramas}
                  showDollhouseTab={showDollhouseTab}
                  showOrderDollhouse={showOrderDollhouse}
                  changePaginationPage={changePaginationPage}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
