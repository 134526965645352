import { IPoints } from "../../components/editing-viewer/interaces";
import { EnumShape } from "../../components/main/settings/modal-optimise-tripod-global";
import { EnumDollhouseStatus } from "../../components/utils/tour-views/interface";

export interface IServerResponse {
	[key: string]: {
		data: any;
	};
}

export interface IPanorama {
	audio: number;
	baseUrl: string;
	compass: number;
	completion: number;
	createdAt: Date;
	cubeMap: string[];
	cubeMapUrls: string[];
	cubePreviewUrl: string;
	cubeUrl: string;
	editOptions: []
	equiPreviewUrl: string;
	equiUrl: string;
	height: number;
	hfov: number;
	id: string;
	latitude: number;
	level: number;
	longitude: number;
	name: string;
	order: number;
	originalUrl: string;
	panoramaOptions: [];
	processingVersion: number;
	readCode: string;
	size: number;
	source: number;
	startPoint: number;
	status: number;
	thumbnailPreviewUrl: string;
	originalPreviewUrl: string;
	enhancedPreviewUrl: string;
	timeStamp: number;
	tourId: string;
	updatedAt: Date;
	userId: string;
	version: number;
	vfov: number;
	views: number;
	width: number;
}

export interface IPanoramaOptions {
	panoramaKey: string;
	panoramaValue: string;
}

export interface IError {
	data: {
		status: {
			code: number;
			message: string;
		};
	};
	status: number;
}

export interface ITokenResponse {
	token: string;
}

export interface ISendPasswordEmail {
	message: string;
	result: boolean;
}

export interface ITours {
	id: string;
	userId: string;
	name: string;
	reference: string;
	furnished: boolean;
	latitude: number;
	longitude: number;
	type: string;
	url: string;
	protected: boolean;
	enableLeadGenerationForm?: string;
	enabled: boolean;
	passwordHash: string;
	status: number;
	readCode: string;
	createdAt: Date;
	updatedAt: Date;
	tourSize?: number;
	panoramaCount?: number;
	mainPhotoUrl?: string;
	numberOfRooms?: number;
	views?: number;
	tourOptions?: ITourOption[];
	shareLocation?: string;
	userEmail?: string;
	tourId?: string;
	dollhouseExist?: string;
	updatedByEmail?: string;
	createdByEmail?: string;
	floorplanExist?: boolean;
}

export interface ITourOption {
	tourId: string;
	tourKey: string;
	tourValue: string;
}

export interface IAccount {
	countryId: string;
	createdAt: string;
	creditTotal: number;
	creditUsed: number;
	email: string;
	fullName: ""
	id: string;
	languageId: string;
	loggedAt: string;
	partnerId: string;
	phoneNumber: string;
	status: string | number;
	tourCount: number;
	updatedAt: string;
	proPlan: EnumProPlan;
	proUser: EnumProUserStatus;
	proExpiration: Date;
	internetEmail: string;
	countryInfo: {
		[key: string]: {
			countryName: string,
			currency: string,
			symbol: string,
		}
	}
}

export enum EnumProPlan {
	Free = "FREE",
	ProPlanMonthly = "PRO_PLAN_MONTH",
	ProPlanYearly = "PRO_PLAN_YEAR",
	// ProExpiring = "PRO EXPIRING",
	// proPendingPayment = "PRO PENDING",
	ProFreeTrail = "PRO_TRIAL",
}

export enum EnumProUserStatus {
	Pro = 1,
	Free = 0,
	ProPendingExpiry = 2,
	ProPendingPayment = 3,
	ProFreeTrial = 4,
}

export const typeProPlan = [
	{
		id: EnumProPlan.Free,
		value: 'Free',
		proUser: EnumProUserStatus.Free,
	},
	{
		id: EnumProPlan.ProPlanMonthly,
		value: 'Pro',
		proUser: EnumProUserStatus.Pro,
	},
	{
		id: EnumProPlan.ProPlanYearly,
		value: 'Pro',
		proUser: EnumProUserStatus.Pro,
	},
	{
		id: EnumProPlan.ProFreeTrail,
		value: 'Trial',
		proUser: EnumProUserStatus.ProFreeTrial,
	},
];

export const typeProPlanNotMaster = [
	{
		id: EnumProPlan.Free,
		value: 'Free',
		proUser: EnumProUserStatus.Free,
	},
	{
		id: EnumProPlan.ProPlanMonthly,
		value: 'Pro',
		proUser: EnumProUserStatus.Pro,
	},
];

export interface IAccountMaster {
	partnerId: string;
	email: string;
	accounts: number;
	status: number;
	createdAt: Date;
	updatedAt: Date;
	countryId: string;
	apiMaxUsers: number;
	adminName: string;
}


export interface IAccountHeadersMasters {
	data: IAccountMaster[];
	headers: any;
}

export interface IAccountHeaders {
	data: IAccount[];
	headers: any;

}

export interface ILeadsGenerated {
	id: string;
	tourId: string;
	email: string;
	fullName: string;
	phoneNumber: string;
	leadType: number;
	formattedLeadType: string;
	createdAt: string;
	updatedAt: string;
	name?: string;
}

export interface ILeadsGeneratedHeaders {
	data: ILeadsGenerated[];
	headers: any;
}

export interface ILeadsGeneratedCSVHeaders {
	data: string;
	headers: any;
}

export interface ILeadsLiveSharingResponse {
	results: ILeadLiveSharing[];
	pageCount: number;
}

export interface ILeadLiveSharing {
	created: Date;
	email: string;
	leadType: number;
	name: string;
	phoneNumber: number
	property: string;
}

export interface ITopTours {
	tourId: string;
	viewsCount: string;
	name: string;
}

export interface ITopToursHeaders {
	data: ITopTours[];
	headers: any;
}

export interface IPanoramaViews {
	panoramaId: string;
	panoramaViews: string;
	name: string;
}

export interface ICountriesViews {
	userCountryId: string;
	userCountryName: string;
	viewsFromCountry: string;
}

export interface IDevicesViews {
	device: string;
	viewsFromDevice: string;
}

export interface ISourceViewedFrom {
	source: string;
	viewsFromSource: number;
}

export interface IPanoramaResponse {
	[key: string]: any;
}

export interface IToursHeaders {
	data: ITours[];
	headers: any;
}

export interface IHotspotResponse {
	id: string;
	name: string;
	srcId: string;
	destId: string;
	phi: number;
	theta: number;
	status: number;
	type: number;
	from: number;
	targetYaw: number;
	createdAt: string;
	updatedAt: string;
}

export interface IHotspotCreation {
	createTooltipArgs: any;
	createTooltipFunc: any;
	cssClass: string;
	id: string;
	pitch: number;
	sceneId: string;
	srcId: string;
	type: string;
	typeFrontViewer: number;
	yaw: number;
	destId: string;
	roomName?: string;
	targetYaw?: number;
}

export interface IPanoramaAnnotation {
	id: string,
	panoramaId: string,
	points: IPoints[],
	cssClass: string,
	label: string,
	status: number,
	createdAt: string,
	updatedAt: string
}

export interface IAnnotationResponse {
	data: IPanoramaAnnotation;
	headers: any;
}

export interface IAnnotationsResponse {
	data: IPanoramaAnnotation[];
	headers: any;
}

export interface IReadAllPanoramaLogos {
	id: string;
	status: number;
	cubeUrl: string;
	equiUrl: string;
}

export interface IAnnotationTypesConfig {
	colour?: string;
	name: string;
	value: string;
}

export interface IUpdateAnnotation {
	cssClass: string;
	label: string;
	points: IPoints;
}

export interface INewAnnotation {
	cssClass: string;
	div: any;
	hotspotLabel: string;
	id: string;
	pitch: number;
	text: string;
	type: string;
	yaw: number;
	label: string;
	hotspotColor?: string;
}

export interface ITourDollhouse {
	reference: string;
	readCode: string;
	name: string;
	panoramaCount: number;
	version: number;
	mainPhotoUrl?: string;
	createdByEmail?: string;
	updatedByEmail?: string;
	userId?: string;
	id?: string;
	tourId: string;
	data?: string;
	status?: EnumDollhouseStatus;
	createdBy?: string;
	updatedBy?: string;
	createdAt?: Date;
	updatedAt?: Date;
}

interface IProduct {
	id: string;
	object: string;
	active: boolean;
	created: number;
	description: any;
	images: string[];
	livemode: boolean;
	metadata: any;
	name: string;
	package_dimensions: any;
	shippable: any;
	statement_descriptor: any;
	tax_code: any;
	unit_label: any;
	updated: number;
	url: any;
}

export interface IPrice {
	id: string;
	object: string;
	active: boolean;
	billing_scheme: string;
	created: number;
	currency: string;
	livemode: boolean;
	lookup_key: any;
	metadata: any;
	nickname: any;
	product: IProduct;
	recurring: {
		aggregate_usage: any;
		interval: string;
		interval_count: number;
		usage_type: string;
	};
	tax_behavior: string;
	tiers_mode: any;
	transform_quantity: any;
	type: string;
	unit_amount: number;
	unit_amount_decimal: string;
}

export interface IDollhouseOrder {
	redirectUrl: string;
}

export interface IFloorplans {
	floorplanId: string;
	photoUrl: string;
	hotspots: [
		{
			id: string;
			panoramaId: string;
			photoUrl: string;
			x: number;
			y: number;
			destId: string;
			name: string;
		}
	]
}

export interface ICreateFloorplan {
	id: string;
	photoUrl: string;
	hotspots: [
		{
			id: string;
			panoramaId: string;
			photoUrl: string;
			x: number;
			y: number;
			destId: string;
			name: string;
		}
	]
}

export interface IFloorplanHotspot {
	x: number;
	y: number;
	destId: string;
	floorplanId: string;
}

export interface IPanoramaOptionAudio {
	panoramaId: string;
	panoramaKey: string;
	panoramaValue: string;
	audioUrl: string;
}

export interface ITourSettings {
	shareLocation?: boolean;
	autoBlur?: boolean;
	tripodRemoved?: boolean;
	autoAligned?: boolean;
	autoEnhance?: boolean;
	multiResolution?: boolean;
	tripodMaskRatio?: number;
	tripodMaskShape?: EnumShape;
}

export interface IValidateDollhouse {
	missingLinks: string[];
	pendingPanoramas: string[];
}