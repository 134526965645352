(function webpackUniversalModuleDefinition(root, factory) {
	//Comment for CommonJS2
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	//Comment for AMD
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	//Comment for CommonJS
	else if(typeof exports === 'object')
		exports["common-pannellum-viewer"] = factory();
	//Comment for Root
	else
		root["PannellumViewer"] = factory();
})(this, function() {
return 